import React from "react";
import { useEffect } from "react";
import {
    Container,
    Header,
    Segment,
    List,
    ListItem,
    HeaderContent,
    ListHeader,
    ListDescription,
    Image,
    Card
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },
}

export const StockMarketArticleHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("AIHeaderContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};
export function StockMarketArticle() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 250, padding: "1em 0em" }}
                vertical>
                <StockMarketArticleHeading />
            </Segment>

            <Container style={{ minHeight: "10rem" }}>
                <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>
                    <Image rounded src='/images/market.jpg' />
                    <a target="_blank" href="https://www.vecteezy.com/free-photos/business">Source: Business Stock photos by Vecteezy</a>
                    <Card fluid style={{ padding: "2em" }}>
                    <Header as='h2'>
                        {t("TitleArticle2")}
                    </Header>
                    <HeaderContent>
                        {t("DescriptionArticle2")}
                    </HeaderContent>
                    <Container>
                        <Header style={{marginTop: "2em"}}>
                            {t("SubTitleArticle2")}
                        </Header>
                        <HeaderContent>
                            {t("SubTitleArticle2Description")}
                        </HeaderContent>

                        <ListHeader as="h3">
                            {t("StructureOfStockTitle")}
                        </ListHeader>
                        <p>{t("ShortDescriptionofStock")}</p>

                        <List ordered>
                            <ListItem>
                                <ListHeader>{t("MainMarketBold")} </ListHeader>
                                <p>{t("MainMarketItem1")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("NewConnectBold")} </ListHeader>
                                <p>{t("NewConnectItem")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("CatalystBold")}</ListHeader>
                                <p>{t("CatalystItem")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("DerivativesBold")} </ListHeader>
                                <p>{t("DerivativesItem")}</p>
                            </ListItem>
                        </List>

                        <ListHeader as="h3">
                            {t("KeyPlayersIndicesTitle")}
                        </ListHeader>
                        <p>{t("ShortDescriptionofIndices")}</p>
                        <List ordered>
                            <ListItem>
                                <ListHeader>{t("WIGBold")} </ListHeader>
                                <p>{t("WIGItem")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("WIG2OBold")} </ListHeader>
                                <p>{t("WIG2OItem")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("WIG40Bold")}</ListHeader>
                                <p>{t("WIG40Item")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("BlueChipBold")} </ListHeader>
                                <p>{t("BlueChipItem")}</p>
                            </ListItem>
                        </List>

                        <ListHeader as="h3">
                            {t("TrendAndDevelopmentsTitle")}
                        </ListHeader>
                        <p>{t("ShortDescriptionofTrends")}</p>
                        <List ordered>
                            <ListItem>
                                <ListHeader>{t("TechnologicalBold")} </ListHeader>
                                <p>{t("TechnologicalItem")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("ForeignInvestmentBold")} </ListHeader>
                                <p>{t("ForeignInvestmentItem")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("ESGBold")}</ListHeader>
                                <p>{t("ESGItem")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("ReformsBold")} </ListHeader>
                                <p>{t(" ReformsItem")}</p>
                            </ListItem>
                        </List>

                        <ListHeader as="h3">
                            {t("ProspectsTitle")}
                        </ListHeader>
                        <p>{t("ShortDescriptionofProspects")}</p>
                        <List ordered>
                            <ListItem>
                                <ListHeader>{t("MarketVolatilityBold")} </ListHeader>
                                <p>{t("MarketVolatilityltem")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("CorporateGovernanceBold")} </ListHeader>
                                <p>{t("CorporateGovernanceItem")}</p>
                            </ListItem>
                            <ListItem>
                                <ListHeader>{t("LiquidityIssuesBold")}</ListHeader>
                                <p>{t("LiquidityIssuesItem")}</p>
                            </ListItem>
                            
                        </List>
                        <ListDescription >{t("EndTextDescription")}</ListDescription>
                        <Container textAlign="justified">
                            <HeaderContent style={{marginTop: "2em"}}>
                            <b>{t("EndArticle2Text1")}</b>
                            </HeaderContent>
                            
                            <p>{t("EndArticle2Text2")}</p>
                        </Container>
                    </Container>
                    </Card>
                </Segment>
            </Container>
        </>
    )
}