import React from "react";
import {
  Container,
  Segment,
  Grid,
  Header,
  GridRow,
} from "semantic-ui-react";
import TilesComponent from "./Tiles/TilesComponent";
import TilesComponent2 from "./Tiles/TilesComponent2";
import { UserContext } from "App";
import { useContext } from "react";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";
import { useEffect } from "react";


export const SentymentHeader = function () {
  const { t } = useTranslation();
  return (

    <Container text>
      <Header
        as="h1"
        content={t("NavSentimentHeader")}
        inverted
        style={{
          fontSize: "3em",
          fontWeight: "normal",
          marginBottom: 0,
          marginTop: "1em",
        }}
      />
    </Container>
  )
};
export function Sentyment({ setShowLogin }: { setShowLogin: (show: any) => void }) {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  if (!user.loggedIn) {
    setShowLogin(true);
    return (<></>);
  }
  return (
    <>
      <Segment
        inverted
        textAlign="center"
        style={{ minHeight: 200, padding: "1em 0em" }}
        vertical
      >
        <SentymentHeader />
      </Segment>
      <Container>
        {/* <Segment>Sentyment page
      {user.loggedIn && (
        <Segment style={{ padding: "8em 0em" }} vertical>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" style={{ fontSize: "2em", marginLeft: "25%" }}>
                  SENTYMENT
                </Header>
                <Header as="h3" style={{ fontSize: "2em", marginLeft: "25%" }}>
                  Analizuj nastroje na rynku przy pomocy ai
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <TilesComponent />
            </Grid.Row>
          </Grid>
        </Segment>
      )}
      </Segment> */}

        <Grid container stackable verticalAlign="middle" style={{ margin: "2em" }}>
          <GridRow>
            <Grid.Column>
              {/* <Header as="h4" style={{ fontSize: "2em", marginLeft: "40%" }}>
                {t("SentimentHeader1")}
              </Header> */}
              <Header as="h4" style={{ fontSize: "2em", marginLeft: "25%" }}>
                {t("SentimentHeader2")}
              </Header>
            </Grid.Column>
          </GridRow>
          <Grid.Row>
            <TilesComponent2 />
          </Grid.Row>
        </Grid>

      </Container>
    </>
  );
}
