import React, { useEffect, useState, PureComponent } from "react";
import toast from "react-hot-toast";
import { ResponsiveContainer, Treemap } from "recharts";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import {
  Container,
  Icon,
  Menu,
  MenuItem,
  Tab,
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Table,
  TableCell,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableHeader,
  HeaderContent,
  Segment,


} from "semantic-ui-react";
import { request, setAuthToken } from "services/axios_interceptor";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const getDateFrom = (timePeriod: string) => {
  switch (timePeriod) {
    case "1 D":
      return DateTime.now()
        .minus({ day: 1 })
        .endOf("day")
        .toISO()
        .split("T")[0];
    case "1 W":
      return DateTime.now()
        .minus({ week: 1 })
        .endOf("day")
        .toISO()
        .split("T")[0];
    case "1 M":
      return DateTime.now()
        .minus({ month: 1 })
        .endOf("day")
        .toISO()
        .split("T")[0];
    case "3 M":
      return DateTime.now()
        .minus({ month: 3 })
        .endOf("day")
        .toISO()
        .split("T")[0];
    case "6 M":
      return DateTime.now()
        .minus({ month: 6 })
        .endOf("day")
        .toISO()
        .split("T")[0];
    case "1 Y":
      return DateTime.now()
        .minus({ year: 1 })
        .endOf("day")
        .toISO()
        .split("T")[0];
  }
};

export default function TilesComponent2() {
  const { t } = useTranslation();
  const [menu, setMenu] = useState("market");
  const [context, setContext] = useState("positive");
  const [tileData, setTileData] = useState<any>();
  const [timePeriod, setTimePeriod] = useState("1 D");
  const [topicTabledata, setTopicTabledata] = useState([{ name: "", color: "", averageBlueshiftSentiment: 0, articles: [{ title: "", url: "" }] }]);
  const [showTable, setShowTable] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalContent, setModalContent] = useState({ title: "", articles: [{ title: "", url: "" }] })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  let resultSort = [{ name: "", color: "", averageBlueshiftSentiment: 0, articles: [{ title: "", url: "" }] }];
  if (Array.isArray(topicTabledata)) {
    resultSort = topicTabledata.filter(function (item) {
      if (context === "positive") {
        if (item.averageBlueshiftSentiment > 0) {
          return true;
        } else {
          return false;

        }
      }
      if (context === "negative") {
        if (item.averageBlueshiftSentiment < 0) {
          return true;
        } else {
          return false;
        }
      }

    });
    console.log(topicTabledata);

    resultSort.sort((a, b) => (a.averageBlueshiftSentiment < b.averageBlueshiftSentiment) ? 1 : ((b.averageBlueshiftSentiment < a.averageBlueshiftSentiment) ? -1 : 0))
    resultSort.forEach((item, key) => {
      if (item.averageBlueshiftSentiment > 0) {
        item.color = "#ccffbf";
      } else {
        item.color = "#ffe4de";
      }
    });
  }
  const responseTopic = resultSort;
  const handleTreeClick = function (param: any) {
    setTopicTabledata(param);
    console.log(param)
  };
  const COLORS = ['#8889DD', '#9597E4', '#8DC77B', '#A5D297', '#E2CF45', '#F8C12D'];

  const CustomizedContent = (props: any) => {
    const { root, depth, x, y, width, height, index, colors, name, value } = props;

    var nameLength = '';
    var fontSize = 14;
    var splitName = [];
    if (name) {
      nameLength = name.length;
    }

    if (nameLength !== '') {
      if (width < 500 && parseInt(nameLength) > 25) {
        fontSize = 14;
        splitName = name.split(" ");
      }
    }
    const info = {
      name: name,
      width: width,
      length: nameLength,
      words: splitName
    }

    return (
      <g>
        <rect
          x={x}
          y={y}
          onClick={(e) => handleTreeClick(e)}
          width={width}
          height={height}
          style={{
            fill:
              depth < 2
                ? colors[Math.floor((index / root.children.length) * 6)]
                : "none",
            stroke: "#fff",
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10)
          }}
        />
        {depth === 1 && splitName.length === 0 ? (
          <text
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor="middle"
            fill="#fff"
            fontSize={fontSize}
          >
            {name}
          </text>

        ) : null}
        {splitName.map((item: any, index: any) =>
          <text
            x={x + width / 2}
            y={(y + height / 4 + 7) + (index * 16)}
            textAnchor="middle"

            fontSize={fontSize}
          >
            {item}
          </text>

        )}
        {/* {depth === 1 ? (
          <text x={x + 4} y={y + 18} fill="#fff" fontSize={16} fillOpacity={0.9}>
            {index + 1}
          </text>
        ) : null} */}
      </g>
    );
  };
  const generatedData = tileData
    ? tileData
      .filter((x: any) => {
        if (context === "positive") {
          return x.averageBlueshiftSentiment > 0;
        }

        if (context === "negative") {
          return x.averageBlueshiftSentiment < 0;
        }

        return false;
      })
      .map((x: any) => {
        return {
          name: x.name,
          children: [
            {
              name: x.name,
              size:
                (x.averageBlueshiftSentiment + 1) / 2,
            },
          ],
        };
      })
    : [];
  useEffect(() => {
    request("POST", "/recommendations/sentiment/tiles", {
      requestType: menu === "market" ? "TOPIC" : "ASSET",
      bodyLengthFrom: "100",
      bodyLengthTo: "10000",
      dateFrom: getDateFrom(timePeriod),
      dateTo: new Date().toISOString().split("T")[0],
    })
      .then((response) => {
        setTileData(response.data.data);

        setTopicTabledata(response.data.data);
      })
      .catch((e) =>{
        console.log(e);
        toast.error(
          t("errorMessage")
        )
      }
       
      );
  }, [menu, timePeriod]);
  const panes = [
    // { menuItem: "1 D", render: () => <></> },
    // { menuItem: "1 W", render: () => <></> },
    // { menuItem: "1 M", render: () => <></> },
    { menuItem: "3 M", render: () => <></> },
    { menuItem: "6 M", render: () => <></> },
    { menuItem: "1 Y", render: () => <></> },
  ];

  return (
    <>
      <Segment stackable verticalAlign="middle" style={{ marginBottom: "2em" }}>
        <HeaderContent style={{ marginBottom: "1em" }}>{t("MarketSentimentAnalysisAIText1")}</HeaderContent>
        <HeaderContent style={{ marginBottom: "1em" }}>{t("MarketSentimentAnalysisAIText2")}</HeaderContent>
        <HeaderContent style={{ marginBottom: "1em" }}>{t("MarketSentimentAnalysisAIText3")}</HeaderContent>
      </Segment>

      <Container style={{ marginTop: "3em" }}>
        <Menu style={{ width: "380px", marginLeft: "33%" }}>
          <MenuItem
            name="market"
            active={menu === "market"}
            onClick={() => setMenu("market")}
            style={{ fontSize: "24px", width: "190px" }}
          >
            {t("MarketFilterButton")}
          </MenuItem>
          <MenuItem
            name="company"
            active={menu === "company"}
            onClick={() => setMenu("company")}
            style={{ fontSize: "24px", width: "190px", }}
          >
            {t("CompanyFilterButton")}
          </MenuItem>
        </Menu>
        <Menu style={{ width: "380px", marginLeft: "33%" }}>
          <MenuItem
            name="positive"
            active={context === "positive"}
            onClick={() => setContext("positive")}
            style={{ fontSize: "15px", width: "190px" }}
          >
            {t("PositiveFilterButton")}
            <Icon color="green" name="arrow up" size="large" />
          </MenuItem>
          <MenuItem
            name="negative"
            active={context === "negative"}
            onClick={() => setContext("negative")}
            style={{ fontSize: "15px", width: "190px" }}
          >
            {t("NegativeFilterButton")}
            <Icon color="red" name="arrow down" size="large" />
          </MenuItem>
        </Menu>
        <Tab
          panes={panes}
          onTabChange={(x: any) => setTimePeriod(x.target.text)}
        />
        {!showTable &&
          <div style={{ borderLeft: "1px solid rgba(34,36,38,.1)", borderRight: "1px solid rgba(34,36,38,.1)", paddingBottom: "0.5em" }}>
            <Button basic style={{ margin: "0.3em" }} onClick={(e) => setShowTable(!showTable)} >{t("ListButton")}</Button>
          </div>
        }
        {showTable &&
          <div style={{ borderLeft: "1px solid rgba(34,36,38,.1)", borderRight: "1px solid rgba(34,36,38,.1)", paddingBottom: "0.5em" }}>
            <Button basic style={{ margin: "0.3em" }} onClick={(e) => setShowTable(!showTable)} >{t("GraphButton")}</Button>
          </div>
        }

        {showTable === false && generatedData.length > 0 && (
          <ResponsiveContainer width="100%" height="50%" >
            <Treemap
              data={generatedData}
              dataKey="size"
              aspectRatio={4 / 3}
              stroke="#fff"
              fill="#eb1e74" // #8884d8
              onClick={(e) => handleTreeClick(e)}
              content={<CustomizedContent colors={COLORS} />}
            />
          </ResponsiveContainer>
        )}
        {generatedData.length === 0 && (
          <div style={{ marginLeft: "35%", marginTop: "5%", fontSize: "15px" }}>{t("SelectionMessage")}</div>
        )}

      </Container>
      <Container >
        {showTable === true && context === "positive" &&
          <Table celled padded color="green">
            <TableHeader >
              <TableRow >
                <TableHeaderCell>{t("THeaderCellEconomicTerm")}</TableHeaderCell>
                <TableHeaderCell>{t("THeaderCellSentimentIndicator")}</TableHeaderCell>
                <TableHeaderCell>{t("THeaderCellArticle")}</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {responseTopic.map((item, i) =>
                <TableRow key={item.name}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.averageBlueshiftSentiment}</TableCell>
                  <TableCell>
                    <Link to=''
                      onClick={() => { setOpen(true); setModalContent({ title: item.name, articles: item.articles }) }}>{item.articles.length}</Link>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        }
        {showTable === true && context === "negative" &&
          <Table celled padded color="red">
            <TableHeader >
              <TableRow >
                <TableHeaderCell>{t("ModalTitleTabel1")}</TableHeaderCell>
                <TableHeaderCell>{t("ModalTitleTabel2")}</TableHeaderCell>
                <TableHeaderCell>{t("ModalTitleTabel3")}</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {responseTopic.map((item, i) =>
                <TableRow key={item.name}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.averageBlueshiftSentiment}</TableCell>
                  <TableCell>
                    <Link to=''
                      onClick={() => { setOpen(true); setModalContent({ title: item.name, articles: item.articles }) }}>{item.articles.length}</Link>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        }
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <ModalHeader>{modalContent.title}</ModalHeader>
          <ModalContent image>
            <Table>
              <TableHeader >
                <TableRow >
                  <TableHeaderCell>{t("ModalTitle")}</TableHeaderCell>
                  <TableHeaderCell>{t("ModalSource")}</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {modalContent.articles.map((article, i) =>
                  <TableRow key={article.title}>
                    <TableCell>{article.title}</TableCell>
                    <TableCell ><a href={article.url} target="_blank">{article.url}</a></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ModalContent>
          <ModalActions>
            <Button primary onClick={() => setOpen(false)}>
              {t("ModalButton")}
            </Button>
          </ModalActions>
        </Modal>
      </Container>
    </>
  );
}
