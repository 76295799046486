import React from "react";
import { useEffect } from "react";
import {
    Container,
    Grid,
    Header,
    Segment,
    Button,
    List,
    ListItem,
    ListDescription,
    ListHeader,
    Card,
    Image,
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },
}

export const AlgorithmicTradingArticleHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("AIHeaderContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};
export function AlgorithmicTradingArticle() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 250, padding: "1em 0em" }}
                vertical>
                <AlgorithmicTradingArticleHeading />
            </Segment>
            <Container style={{ minHeight: "10rem" }}>
                <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>
                    <Card fluid >
                        <Image rounded src='/images/AlgoritmTrading.jpg' />
                        <a target="_blank" href="https://www.vecteezy.com/free-photos/data">Source: Data Stock photos by Vecteezy</a>
                        <Segment>
                            <ListHeader as="h3">
                                {t("Article5Title")}
                            </ListHeader>
                            <p>{t("ShortDescriptionArticle5")}</p>
                            <ListHeader as="h3">
                                {t("Article5Title2")}
                            </ListHeader>
                            <p>{t("ShortDescription2Article5")}</p>
                            <ListHeader as="h4">
                                {t("Article5Title3")}
                            </ListHeader>
                            <p>{t("ShortDescription3Article5")}</p>
                            <ListHeader as="h4">{t("Art5ListTitle")} </ListHeader>
                            <p>{t("ShortDescription4Article5")}</p>

                            <List ordered>

                                <ListItem>
                                    <ListHeader>{t("MarketMakingBold")} </ListHeader>
                                    <p>{t("MarketMakingItem")}</p>
                                </ListItem>
                                <ListItem>
                                    <ListHeader>{t("StatisticalArbitrageBold")}</ListHeader>
                                    <p>{t("StatisticalArbitrageItem")}</p>
                                </ListItem>
                                <ListItem>
                                    <ListHeader>{t("TrendFollowingBold")} </ListHeader>
                                    <p>{t("TrendFollowingItem")}</p>
                                </ListItem>
                                <ListItem>
                                    <ListHeader>{t("MeanReversionBold")} </ListHeader>
                                    <p>{t("MeanReversionItem")}</p>
                                </ListItem>
                                <ListItem>
                                    <ListHeader>{t("EventDrivenStrategiesBold")} </ListHeader>
                                    <p>{t("EventDrivenStrategiesItem")}</p>
                                </ListItem>
                            </List>

                            <ListHeader as="h4">
                                {t("AdvantagesAlgorithmicTitle")}
                            </ListHeader>
                            <p>{t("ShortDescriptionAdvantagesAlgorithmic")}</p>
                            <List bulleted>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("SpeedEfficiencyBold")}</b>
                                        {t("SpeedEfficiencyItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("ReducedEmotionalBiasBold")}</b>
                                        {t("ReducedEmotionalBiasItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("ScalabilityBold")}</b>
                                        {t("ScalabilityItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("EnhancedBacktestingBold")}</b>
                                        {t("EnhancedBacktestingItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h4">
                                {t("ChallengesRisksTitle")}
                            </ListHeader>
                            <p>{t("ShortDescriptionChallengesRisks")}</p>
                            <List bulleted>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("MarketVolatilityBold1")}</b>
                                        {t("MarketVolatilityItem1")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("TechnicalFailuresBold")}</b>
                                        {t("TechnicalFailuresItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("RegulatoryScrutinyBold")}</b>
                                        {t("RegulatoryScrutinyItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("ComplexityTransparencyBold")}</b>
                                        {t("ComplexityTransparencyItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h4">
                                {t("AlgorithmicTradingFutureTitle")}
                            </ListHeader>
                            <p>{t("ShortDescriptionAlgorithmicTradingFutures")}</p>
                            <List bulleted>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("AIMachineLearningBold")}</b>
                                        {t("AIMachineLearningItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("QuantumComputingBold")}</b>
                                        {t("QuantumComputingItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("BlockchainBold")}</b>
                                        {t("BlockchainItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("SustainableEthicalTradingBold")}</b>
                                        {t("SustainableEthicalTradingItem")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <ListHeader as="h4">{t("ConclusionArt5")}</ListHeader>
                            <ListDescription>{t("ConclusionArt5Text")}</ListDescription>
                        </Segment>
                    </Card>
                </Segment>
                <Container />
            </Container>
        </>
    )
}