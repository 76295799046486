import React from "react";
import { useState, useEffect } from "react";
import {
    Container,
    Header,
    Segment,
    List,
    ListItem,
    ListHeader,
    Image,
    ListDescription,
    Card
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },
}

export const RiskManagementArticleHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("AIHeaderContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};
export function RiskManagementArticle() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 250, padding: "1em 0em" }}
                vertical>
                <RiskManagementArticleHeading />
            </Segment>

            <Container style={{ minHeight: "10rem" }}>
                <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>
                    <Image rounded src='/images/RiskManagement.jpg' />
                    <a target="_blank" href="https://www.vecteezy.com/free-photos/risk-management">Source: Risk Management Stock photos by Vecteezy</a>
                    <Card fluid style={{ padding: "2em" }}>
                    <ListHeader as="h3">
                        {t("Article3Title")}
                    </ListHeader>
                    <p>{t("ShortDescriptionArticle3")}</p>

                    <ListHeader as="h4">
                        {t("Article3Subtitle")}
                    </ListHeader>
                    <p>{t("SubtitleShortDescriptionArticle3")}</p>

                    <List ordered>
                        <ListItem>
                            <ListHeader>{t("RiskManagementTitle")} </ListHeader>
                            <p>{t("RiskManagementShortText")}</p>
                        </ListItem>
                        <ListItem>
                            <ListHeader>{t("RiskIdentificationBold")} </ListHeader>
                            <p>{t("RiskIdentificationItem")}</p>
                        </ListItem>
                        <ListItem>
                            <ListHeader>{t("RiskEvaluationBold")}</ListHeader>
                            <p>{t("RiskEvaluationItem")}</p>
                        </ListItem>
                        <ListItem>
                            <ListHeader>{t("RiskTreatmentBold")} </ListHeader>
                            <p>{t("RiskTreatmentItem")}</p>
                        </ListItem>
                        <ListItem>
                            <ListHeader>{t("RiskMonitoringReviewBold")} </ListHeader>
                            <p>{t("RiskMonitoringReviewItem")}</p>
                        </ListItem>
                        <ListItem>
                            <ListHeader>{t("CommunicationConsultationBold")} </ListHeader>
                            <p>{t("CommunicationConsultationItem")}</p>
                        </ListItem>
                    </List>

                    <ListHeader as="h4">
                        {t("RiskTypesTitle")}
                    </ListHeader>
                    <p>{t("ShortDescriptionRiskTypes")}</p>
                    <List ordered>
                        <ListItem >
                            <ListHeader>{t("FinancialRisksBold")}</ListHeader>
                            <ListItem >
                                {t("FinancialRisksItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListHeader>{t("OperationalRisksBold")}</ListHeader>
                            <ListItem>
                                {t("OperationalRisksItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListHeader>{t("StrategicRisksBold")}</ListHeader>
                            <ListItem>
                                {t("StrategicRisksItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListHeader>{t("ComplianceRisksBold")}</ListHeader>
                            <ListItem>
                                {t("ComplianceRisksItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListHeader>{t("ReputationalRisksBold")}</ListHeader>
                            <ListItem>
                                {t("ReputationalRisksItem")}
                            </ListItem>
                        </ListItem>
                    </List>
                    <ListHeader as="h4">
                        {t("BenefitsOfRiskManagementTitle")}
                    </ListHeader>
                    <p>{t("BenefitsOfRiskManagementDescription")}</p>
                    <List bulleted>
                        <ListItem >
                            <ListItem >
                                <b>{t("EnhancedDecisionMakingBold")}</b>
                                {t("EnhancedDecisionMakingItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("IncreasedResilienceBold")}</b>
                                {t("IncreasedResilienceItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("CostSavingsBold")}</b>
                                {t("CostSavingsItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("ImprovedStakeholderConfidenceBold")}</b>
                                {t("ImprovedStakeholderConfidenceItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("CompetitiveAdvantageBold")}</b>
                                {t("CompetitiveAdvantageItem")}
                            </ListItem>
                        </ListItem>
                    </List>
                    <ListHeader as="h4">
                        {t("ChallengesInRiskManagementTitle")}
                    </ListHeader>
                    <p>{t("ChallengesInRiskManagementDescription")}</p>
                    <List bulleted>
                        <ListItem >
                            <ListItem >
                                <b>{t("ComplexityBold")}</b>
                                {t("ComplexityItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("DataAndInformationBold")}</b>
                                {t("DataAndInformationItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("ResourceConstraintsBold")}</b>
                                {t("ResourceConstraintsItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("CulturalBarriersBold")}</b>
                                {t("CulturalBarriersItem")}
                            </ListItem>
                        </ListItem>
                    </List>
                    <ListHeader as="h4">
                        {t("FutureTrendsInRiskManagementTitle")}
                    </ListHeader>
                    <p>{t("FutureTrendsInRiskManagementDescription")}</p>
                    <List bulleted>
                        <ListItem >
                            <ListItem >
                                <b>{t("TechnologyIntegrationBold")}</b>
                                {t("TechnologyIntegrationItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("CybersecurityBold")}</b>
                                {t("CybersecurityItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("SustainabilityESGBold")}</b>
                                {t("SustainabilityESGItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("RegulatoryChangesBold")}</b>
                                {t("RegulatoryChangesItem")}
                            </ListItem>
                        </ListItem>
                        <ListItem >
                            <ListItem >
                                <b>{t("GlobalizationBold")}</b>
                                {t("GlobalizationItem")}
                            </ListItem>
                        </ListItem>
                    </List>
                    <ListDescription>{t("ConclusionArt3")}</ListDescription>
                    </Card>  
                </Segment>
                
            </Container>

        </>
    )
}