import React from "react";
import { useEffect } from "react";
import {
    Container,
    Header,
    HeaderContent,
    Segment,
    List,
    ListItem,
    ListHeader,
    ListList,
    ListContent,
} from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Trans } from "react-i18next";
import { LinkText } from "components/LinkText";

import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },

}

export const PrivacyPolicyPageHeading = function () {
    const { t } = useTranslation();

    return (
        <Container text >
            <Header
                as="h1"
                content={t("PrivacyPolicyHeaderContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};

export function PrivacyPolicyPage({ link1, link2 }: { link1: string, link2: string }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 220 }}
                vertical>
                <PrivacyPolicyPageHeading />
            </Segment>

            <Segment vertical >
                <Container style={{ minHeight: "10rem" }} textAlign="justified">
                    <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>
                        <Header as='h2' textAlign='center' style={{ marginBottom: "2em" }}>
                            {t("PrivacyPolicyTitle")}
                        </Header>
                        <HeaderContent textAlign="justified">
                            <Trans
                                i18nKey="PrivacyPolicyText"
                                components={{
                                    link1: <LinkText to={link1} title="link1" />
                                }} />
                        </HeaderContent>
                        <HeaderContent textAlign="justified" style={{ marginTop: "0.5em" }}>
                            {t("PrivacyPolicyText2")}
                        </HeaderContent>
                        <Container textAlign="justified">
                            <List ordered>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("DataControllerBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("DataControllerItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("PurposesOfPersonalDataProcessingBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("PurposesOfPersonalDataProcessingItem")}
                                    </ListItem>

                                    <ListList as='ul'>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("PurposeOfPDPItem1")}
                                        </ListItem>

                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("PurposeOfPDPItem2")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("PurposeOfPDPItem3")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("PurposeOfPDPItem4")}
                                        </ListItem>
                                    </ListList>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("LegalBasisForPersonalDataProcessingBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("LegalBasisForPersonalDataProcessingText1")}
                                    </ListItem>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("LegalBasisForPersonalDataProcessingText2")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("TypesOfProcessedDataBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("TypesOfProcessedDataItem1")}
                                    </ListItem>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("TypesOfProcessedDataItem2")}
                                    </ListItem>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("TypesOfProcessedDataItem3")}
                                    </ListItem>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("TypesOfProcessedDataItem4")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("SharingPersonalDataDataRecipients")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("SharingPersonalDataDataRecipientsItem1")}
                                    </ListItem>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("SharingPersonalDataDataRecipientsItem2")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("PayUAsPaymentOperator")}</ListHeader>
                                    <ListItem >
                                        <Trans
                                            i18nKey="PayUAsPaymentOperatorItem"
                                            components={{
                                                link2: <LinkText to={link2} title="link2" />
                                            }} />
                                    </ListItem>

                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("RightsOfIndividualsWhoseDataAreProcessed")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("RightsOfIndividualsWhoseDataAreProcessedItem")}
                                    </ListItem>
                                    <ListList as='ul'>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("RightsOfIndividualsWhoseDataAreProcessed1")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("RightsOfIndividualsWhoseDataAreProcessed2")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("RightsOfIndividualsWhoseDataAreProcessed3")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("RightsOfIndividualsWhoseDataAreProcessed4")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("RightsOfIndividualsWhoseDataAreProcessed5")}
                                        </ListItem>
                                    </ListList>

                                    <ListContent style={{ marginLeft: "0.5em" }}>

                                        {t("RightsOfIndividualsWhoseDataAreProcessed6")}
                                    </ListContent>

                                    <ListList as='ul'>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            <Trans
                                                i18nKey="RightsOfIndividualsWhoseDataAreProcessed7"
                                                components={{
                                                    link1: <LinkText to={link1} title="link1" />
                                                }} />
                                        </ListItem>

                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("RightsOfIndividualsWhoseDataAreProcessed8")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("RightsOfIndividualsWhoseDataAreProcessed9")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("RightsOfIndividualsWhoseDataAreProcessed10")}
                                        </ListItem>
                                    </ListList>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("CookiesBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("CookiesItem1")}
                                    </ListItem>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("CookiesItem2")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("RetentionPeriodForPersonalData")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("RetentionPeriodForPersonalData1")}
                                    </ListItem>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("RetentionPeriodForPersonalData2")}
                                    </ListItem>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("RetentionPeriodForPersonalData3")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("ChangesToThePrivacyPolicyBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("ChangesToThePrivacyPolicyItem")}
                                    </ListItem>

                                </ListItem>
                            </List>

                        </Container>

                    </Segment >
                </Container >
            </Segment >
        </>
    )
}