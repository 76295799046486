
import React, { useEffect } from "react";
import {
    Container,
    Grid,
    Header,
    Icon,
    Image,
    Item,
    Label,
    Menu,
    Segment,

    GridColumn,
    GridRow,
    Divider,
    CardGroup,
    Card
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },
}
const srcAI = './images/AI.jpg';
const srcfistbump = './images/fistbump.jpg';
const srcmarket = './images/market.jpg';
const srcmarket2 = './images/market2.png';

export const AboutUsHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("AboutUsHeaderContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};

export function AboutUsPage() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 250, padding: "1em 0em" }}
                vertical>
                <AboutUsHeading />
            </Segment>

            <Segment style={{ padding: "2em 2em", marginBottom: "2em" }} vertical>
                <Grid columns={'equal'} container stackable verticalAlign="middle" style={{ marginBottom: "2em" }}>
                    <Grid.Row>
                        <Grid.Column >
                            <Header >{t("HeaderTopColumn1")}</Header>
                            <p color='blue'>{t("HeaderDescription")}</p>
                            <p>{t("HeaderDescription2")}</p>
                        </Grid.Column>
                        <Grid.Column >
                            <Image
                                rounded
                                src={srcAI}
                            />
                            <a target="_blank" href="https://www.vecteezy.com/free-photos/ai">Source: Ai Stock photos by Vecteezy</a>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column >
                            <Image
                                rounded
                                src={srcmarket}
                                floated='right'
                                style={{ margin: "0em" }}
                            />
                            <a target="_blank" href="https://www.vecteezy.com/free-photos/business">Source: Business Stock photos by Vecteezy</a>
                        </Grid.Column>
                        <Grid.Column >
                            <Header>{t("HeaderTopColumn2")}</Header>
                            <p color='blue'>{t("HeaderDescription4")}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column >
                            <Header> {t("HeaderTopColumn3")}</Header>
                            <p color='blue'>{t("HeaderDescription5")}</p>
                            <p>{t("MisionDescription1")} </p>
                            <p>{t("MisionDescription2")}</p>
                        </Grid.Column>
                        <Grid.Column >
                            <Image
                                rounded
                                src={srcfistbump}
                            />
                            <a target="_blank" href="https://www.vecteezy.com/free-photos/fist-bump">Source: Fist Bump Stock photos by Vecteezy</a>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                <Container textAlign='center' style={{ padding: "2em", marginTop: "2rem" }}>
                    <Header color='blue'>
                        {t("HeaderContainerBluetext")}
                    </Header>
                    <Header as='h4'>
                        {t("HeaderContainertext")}
                    </Header>
                    <Header as='h4'>{t("HeaderPricetext1")}</Header>
                    <b>{t("HeaderPricetext2")}</b>
                </Container>

            </Segment>
        </>
    );
}
