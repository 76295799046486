
import React, { useEffect } from "react";
import { useState } from "react";
import {
    Container,
    Header,
    Segment,
    List,
    ListList,
    ListItem,
    HeaderContent,
    ListHeader,
} from 'semantic-ui-react'
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },

}

export const AIEducationHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("AIEducationHeader")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};

export function AIEducation() {
    const [isActive, setIsActive] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
             <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 200  }}
                vertical>
                <AIEducationHeading />
            </Segment> 

            <Segment vertical >
                {/* <Header
                    as='h2'
                    content={t("InvestInKnowledge")}
                    style={style.h3}
                    textAlign='center'
                /> */}
                <Container style={{ minHeight: "10rem" }} textAlign="justified">
                    <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>
                        <Header as='h2' textAlign='center' style={{ marginBottom: "2em" }}>
                            {t("AITitle")}
                        </Header>
                        <HeaderContent textAlign="justified">
                            {t("AIBeginingText")}
                        </HeaderContent>
                        <Container textAlign="justified">
                            <Header style={{ marginTop: "2em" }}>
                                {t("WhatIsAI")}
                            </Header>
                            <HeaderContent>
                                {t("WhatIsAIText")}
                            </HeaderContent>

                            <ListHeader style={{ marginTop: "1em" }} as="h3">
                                {t("HowAIWorkTitle")}
                            </ListHeader>
                            <HeaderContent>
                                {t("HowAIWorkContent")}
                            </HeaderContent>
                            <List ordered>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("MachineLearningBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("MachineLearningItem")}
                                        <ListList as='ul'>
                                            <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                                <ListHeader>{t("SupervisedLearningBold")}</ListHeader>
                                                <ListItem as='li' style={{ marginTop: "0.5em" }}>
                                                    {t("SupervisedLearningItem")}
                                                </ListItem>
                                            </ListItem>
                                            <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                                <ListHeader>{t("UnsupervisedLearningBold")}</ListHeader>
                                                <ListItem style={{ marginTop: "0.5em" }}>
                                                    {t("UnsupervisedLearningItem")}
                                                </ListItem>
                                            </ListItem>
                                            <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                                <ListHeader>{t("ReinforcementLearningBold")}</ListHeader>
                                                <ListItem style={{ marginTop: "0.5em" }}>
                                                    {t("ReinforcementLearningItem")}
                                                </ListItem>
                                            </ListItem>
                                        </ListList>
                                    </ListItem>
                                </ListItem>

                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("DeepLearningBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("DeepLearningItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("NLPBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("NLPItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("ComputerVisionBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("ComputerVision1Item")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <ListHeader as="h3">
                                {t("ApplicationsOfAITitle")}
                            </ListHeader>
                            <Container textAlign="justified">
                                <p>{t("ApplicationsOfAIContent")}</p>
                            </Container>

                            <List bulleted>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("HealthcareBold2")}</b>
                                        {t("HealthcareItem2")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("FinanceBold2")}</b>
                                        {t("FinanceItem2")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("EntertainmentBold")}</b>
                                        {t("EntertainmentItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("ManufacturingBold2")}</b>
                                        {t("ManufacturingItem2")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h3">
                                {t("ChallengesAndFutureOfAITitle")}
                            </ListHeader>
                            <Container textAlign="justified">
                                <p>{t("ChallengesAndFutureOfAIText")}</p>
                            </Container>
                            
                            <List bulleted>
                            <ListItem >
                                    <ListItem >
                                        <b>{t("EthicalConcernsBold")}</b>
                                        {t("EthicalConcernsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("DataRequirementsBold")}</b>
                                        {t("DataRequirementsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("InterpretabilityBold")}</b>
                                        {t("InterpretabilityItem")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <HeaderContent>
                                {t("AIFutureChallegesText")}
                            </HeaderContent>
                            <Container textAlign="justified">
                                <Header style={{ marginTop: "1em" }}>{t("AIConclusion")}</Header>
                                <HeaderContent>{t("AIConclusionText")}</HeaderContent>
                            </Container>
                        </Container>
                    </Segment>
                </Container>
            </Segment>
        </>
    );
}
