import React from "react";
import { NavBar } from "components/Navbar";
import { HistoricalPage } from "pages/Historical";
import { InstrumentHistory2 } from "pages/Historical/Individual/InstrumentHistory2";
import {  XaiLime2 } from "pages/Historical/Individual/XaiLime2";
import { Footer, HomePage } from "pages/Home";
import PrognosisPage from "pages/Prognosis/PrognosisPage";
import { useState } from "react";
import { InView } from "react-intersection-observer";
import {  Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import {AboutUsPage} from "pages/AboutUs";
import {OfferPage} from "pages/Offer";
import {Sentyment} from "pages/Sentyment";
import { Stocks } from "pages/Stocks/Stocks";
import { Indexes } from "pages/Indexes/Indexes";
import AccountConfirmation from "pages/AccountConfirmation/AccountConfirmation";
import ResetPassordModal from "components/Navbar/components/ResetPasswordModal";
import NewPassword from "pages/NewPasswod/New.Password";
import { AI } from "pages/Education/AI";
import { SubscriptionTypes } from "pages/Payments/SubscriptionTypes";
import { AIarticle } from "pages/Education/AIarticle";
import { StockMarketArticle } from "pages/Education/StockMarketArticle";
import { RiskManagementArticle } from "pages/Education/RiskManagementArticle";
import { StockMarketGuideArticle } from "pages/Education/StockMarketGuideArticle";
import { AlgorithmicTradingArticle } from "pages/Education/AlgorithmicTradingArticle";
import { StockMarketAIarticle } from "pages/Education/StockMarketAIarticle";
import { PrivacyPolicyPage } from "pages/Documentation/PrivacyPolicyPage";
import { TermsConditions } from "pages/Documentation/TermsConditions";
import { ScrollToTop } from "components/ScrollToTop/ScrollToTop";
import { SentimentAnalysis } from "pages/Education/SentimentAnalysis";
import { MarketSentiments } from "pages/Education/MarketSentiments";
import { AIEducation } from "pages/Education/AIEducation";

export function Routing() {
  const [fixed, setFixed] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const  LINK1 = "http://www.StockBrief.pl";
  const  LINK2 = "https://poland.payu.com/prywatnosc/";
  return (
    <Router>
      <InView onChange={(x) => setFixed(!x)}>
        <Segment
          inverted
          textAlign="center"
          style={{ minHeight: 50, padding: "1em 0em" }}
          vertical
        >
          <NavBar fixed={fixed} setShowLogin={setShowLogin} showLogin={showLogin}/>
        </Segment>
      </InView>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/prognosis" element={<PrognosisPage />} />
        <Route path="/historical" element={<HistoricalPage />} />
        <Route path="/recommendation/history/:instrument" element={<InstrumentHistory2 />} />
        <Route path="/recommendation/history/:instrument/xai-lime" element={<XaiLime2 />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/offer" element={<OfferPage setShowLogin={setShowLogin} />} />
        <Route path="/sentyment" element={<Sentyment setShowLogin={setShowLogin}/>} />
        <Route path="/stocks" element={<Stocks setShowLogin={setShowLogin} />} />
        <Route path="/indexes" element={<Indexes setShowLogin={setShowLogin} />} />
        <Route path="/account-confirmation/:confirmKey" element={<AccountConfirmation/>} />
        <Route path="/reset-password/:codeValidation" element={<NewPassword/>} />
        <Route path="/education/AI" element={<AI/>} />
        <Route path="/payments/SubscriptionTypes/:type" element={<SubscriptionTypes/>} />
        <Route path="/education/AI/What-is-AI" element={<AIarticle/>} />
        <Route path="/education/AI/Stock-Market-in-Poland" element={<StockMarketArticle/>} />
        <Route path="/education/AI/Risk-Management-Essential-Strategies-for-Business-Resilience" element={<RiskManagementArticle/>} />
        <Route path="/education/AI/Investing-in-the-Stock-Market-A-Comprehensive-Guide" element={<StockMarketGuideArticle/>} />
        <Route path="/education/AI/Algorithmic-Trading-The-Future-of-Financial-Markets" element={<AlgorithmicTradingArticle/>} />
        <Route path="/education/AI/AI-and-the-Stock-Market-Transforming-Investment-Strategies" element={<StockMarketAIarticle/>} />
        <Route path="/documentation/PrivacyPolicy" element={<PrivacyPolicyPage link1={LINK1} link2={LINK2}/>} />
        <Route path="/documentation/Terms&Conditions" element={<TermsConditions link1={LINK1} link2={LINK2}/>} />
        <Route path="/education/AIEducation" element={<AIEducation/>} />
        <Route path="/education/SentimentAnalysis" element={<SentimentAnalysis/>} />
        <Route path="/education/MarketSentiments" element={<MarketSentiments/>} />
      </Routes>
      <Footer />
      
    </Router>
  );
}
