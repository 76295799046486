import React, { useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Divider,
  Segment,
  CardHeader,
  CardContent,
  Card,
  Image,
  ListItem,
  ListHeader,
  Icon,
  List,
  ListList,
  CardMeta,
  CardDescription,

} from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";
import { useNavigate } from "react-router-dom";
import { UserContext } from "App";
import { useContext } from "react";
import JoinUsEnterprise from "components/EnterpriseModal/JoinUsEnterprise";
import { useState } from "react";


export const OfferPageHeader = function () {
  const { t } = useTranslation();
  return (
    <Container text>
      <Header
        as="h1"
        content={t("OfferHeaderContent")}
        inverted
        style={{
          fontSize: "3em",
          fontWeight: "normal",
          marginBottom: 0,
          marginTop: "1em",
        }}
      />
    </Container>
  )
};
export function OfferPage({ setShowLogin }: { setShowLogin: (show: any) => void }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  console.log(user);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const [openModalEnterprise, setOpenModalEnterprise] = useState(false);
  const colors = {
    functionality: '#fce895',
    basic: '#73aee6',
    premium: '#825aad',
    enterprise: '#f59d51',
    packages: "#fcf9e6"
  }
  return (
    <>
      <Segment
        inverted
        textAlign="center"
        style={{ minHeight: 250, padding: "1em 0em" }}
        vertical
      >
        <OfferPageHeader />
      </Segment>

      <Container stackable verticalAlign="middle">
        <Header as="h2" style={{ margin: "2em" }} >
          {t("HeaderTextOfferPage")}
          <p> {t("HeaderTextOfferPage2")}</p>
        </Header>
        <Grid style={{ margin: "1.2em" }} columns="equal" stackable>
          <GridRow >
            <GridColumn>
              <Card>
                <CardHeader as="h3" textAlign="center" style={{ margin: "1.2em", fontSize: "1.3em" }}>{t("THBasic")}</CardHeader>
                <CardMeta style={{ marginBottom: "1em" }} textAlign="center">{t("MonthlyPriceBasic")}</CardMeta>
                <CardContent>
                  <CardDescription>
                    <List >
                      <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality1")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality2")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality3")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality4")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality5")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality6")}</ListHeader></ListItem>
                    </List>
                  </CardDescription>
                  <CardContent style={{ marginTop: "2em" }}>
                    {user.loggedIn && (
                      <Button fluid primary onClick={() => navigate("/payments/SubscriptionTypes/basic")}>{t("JoinButtonText")}</Button>
                    )}
                    {!user.loggedIn && (
                      <Button fluid primary onClick={() => setShowLogin(true)}>{t("JoinButtonText")}</Button>
                    )}
                  </CardContent>

                </CardContent>
              </Card>
            </GridColumn>
            <GridColumn>
              <Card>
                <CardHeader as="h3" style={{ fontSize: "1.3em", margin: "1.3em" }} textAlign="center">{t("THPremium")}</CardHeader>
                <CardMeta style={{ marginBottom: "1em" }} textAlign="center">{t("MonthlyPricePremium")}</CardMeta>
                <CardContent>
                  <CardDescription>
                    <List >
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality1")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality2")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality3")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality4")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality5")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality6")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality7")}</ListHeader></ListItem>
                    </List>
                  </CardDescription>
                  <CardContent style={{ marginTop: "2em" }}>
                    {user.loggedIn && (
                      <Button fluid primary onClick={() => navigate("/payments/SubscriptionTypes/premium")}>{t("JoinButtonText")}</Button>
                    )}
                    {!user.loggedIn && (
                      <Button fluid primary onClick={() => setShowLogin(true)}>{t("JoinButtonText")}</Button>
                    )}
                  </CardContent>

                </CardContent>
              </Card>
            </GridColumn>
            <GridColumn>
              <Card>
                <CardHeader as="h3" style={{ fontSize: "1.3em", margin: "1.3em" }} textAlign="center" >{t("THEnterprise")}</CardHeader>
                <CardMeta style={{ marginBottom: "1em" }} textAlign="center">{t("RequestQuote")}</CardMeta>
                <CardContent >
                  <CardDescription>
                    <List >
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality1")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality2")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality3")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality4")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality5")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality6")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality7")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality8")}</ListHeader></ListItem>

                    </List>
                  </CardDescription>
                  <CardContent style={{ marginTop: "2em" }}>
                    {user.loggedIn && (
                      <Button fluid primary onClick={() => setOpenModalEnterprise(true)}>{t("JoinButtonText")}</Button>
                    )}
                    {!user.loggedIn && (
                      <Button fluid primary onClick={() => setShowLogin(true)}>{t("JoinButtonText")}</Button>
                    )}
                  </CardContent>
                </CardContent>
              </Card>
            </GridColumn>
          </GridRow>
        </Grid>
      </Container>
      <Container style={{ margin: "2rem" }}>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={9}>
              <Header textAlign="justified">
                <b>
                  {t("OfferDescriptionTextSection2")}
                </b>
              </Header>
              <List>
                <ListItem>
                  <ListList>
                    <ListItem style={{ padding: "1em 0em" }}>
                      <Icon className="chevron down" />
                      {t("HomeSegmentTextPrognoses")}
                    </ListItem>
                    <ListItem style={{ padding: "1em 0em" }}>
                      <Icon className="chevron down" />
                      {t("HomeSegmentTextInstruments")}
                    </ListItem>
                    <ListItem style={{ padding: "1em 0em" }}>
                      <Icon className="chevron down" />
                      {t("HomeSegmentTextMarketAnalysis")}
                    </ListItem>
                  </ListList>
                </ListItem>
              </List>
              <Divider />
              <Button class="ui button" primary>
                {t("LearnMoreButton")}
              </Button>
            </Grid.Column>
            <Grid.Column width={7}>
              <Segment style={{ padding: "4em 0em" }} vertical>
                <Grid container stackable verticalAlign="middle">
                  <Grid.Row>
                    <Grid.Column>
                      <Image src="./images/desktop.png" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <JoinUsEnterprise
        opened={openModalEnterprise}
        openModal={() => setOpenModalEnterprise(true)}
        closeModal={() => setOpenModalEnterprise(false)}
      />
 
    </>
  );
}
