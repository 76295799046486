import React, { useEffect, useState} from "react";
import {
    Container,
    Grid,
    Header,
    Segment,
    Button,
    List,
    ListItem,
    HeaderContent,
    ListHeader,
    Card,
    Image
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },
}

export const AIarticleHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("AIHeaderContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};
export function AIarticle() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 250, padding: "1em 0em" }}
                vertical>
                <AIarticleHeading />
            </Segment>

            <Container style={{ minHeight: "10rem" }}>
                <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>

                    <Image rounded src='/images/AI.jpg' />
                    <a target="_blank" href="https://www.vecteezy.com/free-photos/ai">Source: Ai Stock photos by Vecteezy</a>
                    <Card fluid style={{ padding: "2em" }}>
                        <Header as='h2'>
                            {t("TitleArticle1")}
                        </Header>
                        <HeaderContent textAlign="justified">
                            {t("DescriptionArticle1")}
                        </HeaderContent>
                        <Container>
                            <Header style={{ marginTop: "2em" }}>
                                {t("SubTitleArticle1")}
                            </Header>
                            <HeaderContent>
                                {t("DescriptionSubTArticle1")}
                            </HeaderContent>

                            <ListHeader style={{ marginTop: "1em" }} as="h3">
                                {t("HeaderTypesAI")}
                            </ListHeader>
                            <HeaderContent>
                                {t("DescriptionTypesAI")}
                            </HeaderContent>
                            <List ordered>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader >{t("TitleItem1TypesAI")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("Item1TypesAI")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("TitleItem2TypesAI")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("Item2TypesAI")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("TitleItem3TypesAI")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("Item3TypesAI")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h3">
                                {t("HowAIWorks")}
                            </ListHeader>
                            <Container textAlign="justified">
                                <p>{t("AIdescriptionParagraph")}</p>
                            </Container>

                            <List bulleted>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("MacchineLearning")}</b>
                                        {t("DescriptionMachineLearning")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("NeuralTitle")}</b>
                                        {t("NeuralDescription")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("ComputerVisionTitle")}</b>
                                        {t("ComputerVisionItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h3">
                                {t("AIAplicationTitle")}
                            </ListHeader>
                            <p>{t("ShortDescriptionAIAplication")}</p>
                            <List bulleted>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("HealthcareBold")}</b>
                                        {t("HealthcareItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("FinanceBold")}</b>
                                        {t("FinanceItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("RetailBold")}</b>
                                        {t("RetailItem")}
                                    </ListItem>
                                    <ListItem >
                                        <b>{t("ManufacturingBold")}</b>
                                        {t("ManufacturingItem")}
                                    </ListItem>
                                    <ListItem >
                                        <b>{t("TransportationBold")}</b>
                                        {t("ransportationItem")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <Header>{t("AIFutureTitle")}</Header>
                            <Container textAlign="justified">
                                <p>{t("AIfutureText1")}</p>
                                <p>{t("AIfutureText2")}</p>
                                <p>{t("AIfutureText3")}</p>
                            </Container>
                        </Container>
                    </Card>
                </Segment>

            </Container>
        </>
    )
}