import React from "react";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Flag,
  Menu,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
  Segment
} from "semantic-ui-react";
import styled from "styled-components";
import LoginModal from "./components/LoginModal";
import SignupModal from "./components/SignupModal";
import SignupIndividualModal from "./components/SignupIndividualModal";
import SignupCompanyModal from "./components/SignupCompanyModal";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "services/axios_interceptor";
import { UserContext } from "App";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'
import ResetPassordModal from "./components/ResetPasswordModal";


enum Tabs {
  StockBrief = 0,
  Prognosis = 1,
  Historical = 2,
  AboutUs = 3,
  Offer = 4,
  Sentyment = 5,
  Stocks = 6,
  Indexes = 7,
}
const paths = ["/", "/prognosis", "historical", "about-us", "offer", "sentyment", "stocks", "indexes"];


export function NavBar({ fixed, setShowLogin, showLogin}:{fixed: any, setShowLogin:(show: any) => void, showLogin: any} ) {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  // const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [individualOpen, setIndividualOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [showResetForm, setResetForm] = useState(false);

  const openSignup = () => {
    setShowLogin(false);
    setShowSignup(true);
  };

  const closeLogin = () => {
    // setLoggedIn(false);
    setShowLogin(false);
    // user.updateLogged(true);
  };

  const navigateToTabs = (tab: Tabs) => {
    setActiveTab(tab);
    navigate(paths[tab]);
  };

  const logout = () => {
    window.localStorage.removeItem("auth_token");
    setLoggedIn(false);
    user.updateLogged(false);
    user.loggedIn = false;
    navigate("/");
  };
  const showIndividualModal = () => {
    setIndividualOpen(true);
    setShowSignup(false);
  }
  const showCompanyModal = () => {
    setCompanyOpen(true);
    setShowSignup(false);
  }
  const openResetForm = () => {
    setShowLogin(false);
    setResetForm(true);
  };

  const chooseLanguage = (language: any) => {
    i18n.changeLanguage(language);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
    localStorage.setItem("lang", language);
  }
  return (
    <>
      <Menu
        fixed={fixed ? "top" : undefined}
        inverted={!fixed}
        pointing={!fixed}
        secondary={!fixed}
        size="large"
        content='Responsive Menu'
        stackable
      >
        {/* <Menu.Item>Stock Brief</Menu.Item> */}
        <Menu.Item></Menu.Item>
        {/* <Menu.Item
            as="a"
            active={activeTab === Tabs.StockBrief}
            onClick={() => navigateToTabs(Tabs.StockBrief)}>
           */}
        <Dropdown text='StockBrief' ponting className='link item' >
          <DropdownMenu>
            <DropdownItem onClick={() => navigateToTabs(Tabs.StockBrief)}>{t("Home")}</DropdownItem>
            <DropdownDivider />
            <DropdownItem onClick={() => navigateToTabs(Tabs.Stocks)}>{t("Stocks")}</DropdownItem>
            <DropdownDivider />
            <DropdownItem onClick={() => navigateToTabs(Tabs.Indexes)}>{t("Indexes")}</DropdownItem>
            <DropdownDivider />
            <DropdownItem onClick={() => navigateToTabs(Tabs.Sentyment)} >{t("MarketSentiments")}</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {/* </Menu.Item> */}
        <Menu.Item
          as="a"
          active={activeTab === Tabs.AboutUs}
          onClick={() => navigateToTabs(Tabs.AboutUs)}>
          {t("AboutUs")}
        </Menu.Item>
        <Menu.Item
          as="a"
          active={activeTab === Tabs.Offer}
          onClick={() => navigateToTabs(Tabs.Offer)}>
          {t("Offer")}
        </Menu.Item>

        <Menu.Item position="right">
          {!user.loggedIn && !loggedIn ? (
            <>
              <Button
                as="a"
                inverted={!fixed}
                onClick={() => setShowLogin(true)}
              >
                {t("Login")}
              </Button>
              <Button
                as="a"
                inverted={!fixed}
                primary={fixed}
                style={{ marginLeft: "0.5em" }}
                onClick={() => setShowSignup(true)}
              >
                {t("SignUp")}
              </Button>
            </>
          ) : (
            <Button as="a" inverted={!fixed} onClick={logout}>
              {t("Logout")}
            </Button>
          )}
        </Menu.Item>
        <Menu.Item>
          <Flag name='gb' style={{ cursor: 'pointer' }} onClick={() => chooseLanguage("en")} />
          <Flag name='pl' style={{ cursor: 'pointer' }} onClick={() => chooseLanguage("pl")} />
        </Menu.Item>

        <LoginModal
          showLogin={showLogin}
          closeModal={closeLogin}
          openSignup={openSignup}
          openResetForm={openResetForm}
          setLoggedIn={setLoggedIn}
        />
        <SignupModal
          showSignup={showSignup}
          closeModal={() => setShowSignup(false)}
          showIndividualModal={() => showIndividualModal()}
          showCompanyModal={() => showCompanyModal()}
        />
        <SignupIndividualModal
          showSignup={individualOpen}
          closeModal={() => setIndividualOpen(false)}

        />
        <SignupCompanyModal
          showSignup={companyOpen}
          closeModal={() => setCompanyOpen(false)}
        />
        <ResetPassordModal
          showResetForm={showResetForm}
          closeModal={() => setResetForm(false)}
        />

      </Menu>
    </>
  );
}
