import React from 'react';

export const LinkText = (props: any) => {
    console.log(props);
    return (
        <a href={props.to || '#'} target="_blank" title={props.title || ''}>
            {props.children}
        </a>
    );
};
