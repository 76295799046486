import React from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Grid, Header, Modal, Segment, FormField, Checkbox } from "semantic-ui-react";
import { request, setAuthToken, isValidEmail } from "services/axios_interceptor";
import styled from "styled-components";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'

type SignupForm = {
    agreements: any;
    companyName: string;
    email: string,
    firstName: string;
    isCompany: boolean;
    lastName: string;
    nip: string;
    password: string;

};

function SignupCompanyModal({
    showSignup,
    closeModal,
}: {
    showSignup: boolean;
    closeModal: () => void;
}) {
    const [signUpForm, setSignUpForm] = useState<SignupForm>({
        agreements: true,
        companyName: "",
        email: "",
        firstName: "",
        isCompany: true,
        lastName: "",
        nip: "",
        password: ""
    });

    const submit = () => {
      
        let userDataForm = {
            agreements: signUpForm.agreements,
            companyName: signUpForm.companyName,
            email: signUpForm.email,
            firstName: signUpForm.firstName,
            isCompany: signUpForm.isCompany,
            lastName: signUpForm.lastName,
            nip: signUpForm.nip,
            password: signUpForm.password
        }
        if(isValidEmail(signUpForm.email) === false){
            toast.error(
                (t("CreateAccountIndivEmailIsValid")),
            );
            return;
          }
        request("POST", "/user/create-application", userDataForm)
            .then((response) => {
                //TODO: adjust structure of response when backend part implemented
                setAuthToken(response.data.token);
                toast.success(t("CreateAccountIndivSuccessMessage"));
                closeModal();
            })
            .catch((e) =>
                toast.error(
                    (t("serverErrorMessage")),
                    
                )
            );
    };
    const { t } = useTranslation();
    return (
        <Modal onClose={() => closeModal()} open={showSignup} size="tiny">
            <Modal.Header>{t("SignUpModalHeader")}</Modal.Header>
            <Modal.Content>
                <Grid textAlign="center" verticalAlign="middle">
                    <S.StyledColumn>
                        <Header as="h2" color="black" textAlign="center">
                            {t("CreateAnAccountModal")}
                        </Header>
                        <Form size="large">
                            <Segment stacked>
                                <Form.Input
                                    fluid
                                    placeholder={t("CompanyNameModal")}
                                    onChange={(e) =>
                                        setSignUpForm((prevState) => ({
                                            ...prevState,
                                            companyName: e.target.value,
                                        }))
                                    }
                                />
                                <Form.Input
                                    fluid
                                    placeholder={t("NipModal")}
                                    onChange={(e) =>
                                        setSignUpForm((prevState) => ({
                                            ...prevState,
                                            nip: e.target.value,
                                        }))
                                    }
                                />
                                <Form.Input
                                    fluid
                                    placeholder={t("EmailModal")}
                                    onChange={(e) =>
                                        setSignUpForm((prevState) => ({
                                            ...prevState,
                                            email: e.target.value,
                                        }))
                                    }
                                />
                                <Form.Input
                                    fluid
                                    placeholder={t("PasswordModal")}
                                    type="password"
                                    onChange={(e) =>
                                        setSignUpForm((prevState) => ({
                                            ...prevState,
                                            password: e.target.value,
                                        }))
                                    }
                                />
                                <FormField>
                                <Checkbox label= {<label>{t("CheckboxTermsAcceptance")}  <a href="/documentation/Terms&Conditions">{t("TermsAcceptance")}</a> {t("andWordTermAcceptance")} <a href="/documentation/PrivacyPolicy">{t("PrivacyPolicy")}</a> </label> }
                                        onChange={(e, data) =>
                                            setSignUpForm((prevState) => ({
                                                ...prevState,
                                                agreements: data.checked,
                                            }))
                                        } />
                                </FormField>
                            </Segment>
                        </Form>
                    </S.StyledColumn>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" onClick={() => closeModal()}>
                    {t("CancelSignUpForm")}
                </Button>
                <Button
                    color="green"
                    onClick={() => submit()}
                    disabled={
                        signUpForm.companyName.length === 0 ||
                        signUpForm.nip.length === 0 ||
                        signUpForm.email.length === 0 ||
                        signUpForm.password.length === 0
                    }
                >
                    {t("SubmitSignUpForm")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

const S = {
    StyledColumn: styled(Grid.Column)`
    max-width: 450px;
  `,
};

export default SignupCompanyModal;
