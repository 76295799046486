import React, { useState, useEffect } from "react";
import {
    Container,
    Header,
    Segment
} from "semantic-ui-react";
import { request, setAuthToken } from "services/axios_interceptor";
import toast from "react-hot-toast";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom";

type confirmCreateUser = {
    data: any;
    jwt: string;

};

export default function AccountConfirmation() {
    const { t } = useTranslation();
    const urlParameters = useParams();
    const [accountConfirmationPage, setAccountConfirmationPage] = useState("");
    // let userConfirmation = {
    //     data: true,
    //     jwt: "",
    // }

    useEffect(() => {
        request("GET", `/user/confirm-create-user/${urlParameters.confirmKey}`, {})
            .then((response) => {
                // userConfirmation.data = response.data.confirmKey;
                // userConfirmation.data = response.data.jwt;
                // toast.success("Your account was confirm."); 
                if (!accountConfirmationPage) {
                    if (response.data.data === true) {
                        setAccountConfirmationPage(t("accountConfirmationMessage"));
                    }
                    else {
                        setAccountConfirmationPage(t("account was not confirm"));

                    }
                }
               
            })
            .catch((e) =>
                toast.error(
                    t("serverErrorMessage"),
                    
                )
            );
    }, [])

    // {}
    return (
        <Container>
            <Segment
                textAlign="center"
                style={{ padding: "2em 2em" }}
                vertical>
                <Header as="h3">{accountConfirmationPage}</Header>
            </Segment>
        </Container>
    );
}
