import React from "react";
import { useState, useEffect } from "react";
import {
    Container,
    Header,
    Segment,
    List,
    ListItem,
    ListDescription,
    ListHeader,
    Image,
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },
}

export const StockMarketAIarticleHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("AIHeaderContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};
export function StockMarketAIarticle() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 250, padding: "1em 0em" }}
                vertical>
                <StockMarketAIarticleHeading />
            </Segment>
            <Container style={{ minHeight: "10rem" }}>
            <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>
                <Image rounded src='/images/AIStockMarket.jpg' />
                <p><a target="_blank" href="https://www.vecteezy.com/free-photos/idea">Source: Idea Stock photos by Vecteezy</a></p>
                <Segment>
                    <ListHeader as="h3">
                        {t("Article6Title")}
                    </ListHeader>
                    <p>{t("ShortDescriptionArticle6")}</p>     
                       
                            <ListHeader as="h4">
                                {t("Article6Title2")}
                            </ListHeader>
                            <p>{t("ShortDescription2Article6")}</p>
                            <List ordered>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("AnalyzeHistoricalDataBold")}</b>
                                        {t("AnalyzeHistoricalDataItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("MonitorRealTimeMarketConditions")}</b>
                                        {t("MonitorRealTimeMarketConditionsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("ExecuteTradesBold")}</b>
                                        {t("ExecuteTradesItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("PredictStockPricesBold")}</b>
                                        {t("PredictStockPricesItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("ExecuteTradesBold")}</b>
                                        {t("ExecuteTradesItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h4">
                                {t("BenefitsAIStockTradingTitle")}
                            </ListHeader>
                            <p>{t("BenefitsAIStockTradingDescription")}</p>
                            <List ordered>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("ImprovedAccuracyBold")}</b>
                                        {t("ImprovedAccuracyItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("SpeedBold")}</b>
                                        {t("SpeedItem")}
                                    </ListItem>
                                </ListItem>

                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("DataDrivenDecisionsBold")}</b>
                                        {t("DataDrivenDecisionsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("RiskManagementBold")}</b>
                                        {t("RiskManagementItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("EfficiencyBold")}</b>
                                        {t("EfficiencyItem")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <ListHeader as="h4">
                                {t("ChallengesAndConsiderationsTitle")}
                            </ListHeader>
                            <p>{t("ChallengesAndConsiderationsDescription")}</p>
                            <List ordered>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("Complexity2Item")}</ListHeader>
                                    <p>{t("Complexity2Item")}</p>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("MarketVolatility2Bold")} </ListHeader>
                                    <p>{t("MarketVolatility2Item")}</p>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("DataQualityBold")} </ListHeader>
                                    <p>{t("DataQualityItem")}</p>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("RegulatoryConcernsBold")} </ListHeader>
                                    <p>{t("RegulatoryConcernsItem")}</p>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("EthicalConsiderationsBold")} </ListHeader>
                                    <p>{t("EthicalConsiderationsItem")}</p>
                                </ListItem>
                            </List>
                            <ListHeader as="h4">
                                {t("FutureProspectsTitle")}
                            </ListHeader>
                            <p>{t("FutureProspectsDescription")}</p>
                            <List ordered>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("EnhancedPredictiveModelsBold")}</b>
                                        {t("EnhancedPredictiveModelsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("BlockchainIntegrationBold")}</b>
                                        {t("BlockchainIntegrationItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("PersonalizedInvestmentStrategiesBold")}</b>
                                        {t("PersonalizedInvestmentStrategiesItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListItem >
                                        <b>{t("CollaborationWithHumanTradersBold")}</b>
                                        {t("CollaborationWithHumanTradersItem")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <ListDescription>{t("AIStockMarketConclusion")}</ListDescription>
                            <ListDescription>{t("AIStockMarketConclusionText")}</ListDescription>
                       
                        
                </Segment>
         </Segment>
         </Container>

        </>
    )
}