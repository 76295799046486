import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Grid,
  Header,
  Icon,
  Segment,
  Tab,
  Container
} from "semantic-ui-react";
import { request } from "services/axios_interceptor";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'

// const data = [];

export function XaiLime2() {
  const params = useParams();
  const { t } = useTranslation();
  const [chartData, setChartData] = useState();
  

  useEffect(() => {
    request("GET", `/recommendations/xai/lime-forests/${params.instrument}`, {})
      .then((response) => {
        setChartData(response.data.data);
        console.log(chartData);
      })
      .catch((e) =>
        toast.error(
          t("errorMessage"),
          { position: "bottom-right" }
        )
      );
  }, []);  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Segment stackable >
      <Container text>
        <Header
          as="h1"
          content={t("StocksHeaderContent")}
          inverted
          style={{
            fontSize: "3em",
            fontWeight: "normal",
            marginBottom: 0,
            marginTop: "1em",
          }}
        />
      </Container>
      <Container container stackable verticalAlign="middle">
        <Grid
          container
          stackable
          verticalAlign="middle"
          style={{ marginLeft: "150px" }}
        >
          <Grid.Row>
            <Grid.Column width={5}>
              <Header size="large">XAI</Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={4}>
              <Header>{t("XAIInstrumentname")}</Header>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header>PKN Orlen</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header>{t("XAIForecastLength")}</Header>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header>{t("XAI30days")}</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header>{t("XAIRecommendations")}</Header>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header>
                {t("YesWord")} <Icon name="arrow up" color="green" /> (0.8002089262008667)
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ComposedChart
                layout="vertical"
                width={800}
                height={1000}
                data={chartData}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis type="number" />
                <YAxis dataKey="featureName" type="category" scale="band" />
                <Tooltip />
                <Legend verticalAlign="top" />
                <Bar dataKey="value" name="XAI values" barSize={15} fill="green" />
              </ComposedChart>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}
