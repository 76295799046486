
import React, { useEffect } from "react";
import { useState } from "react";
import {
    Container,
    Grid,
    Header,
    Segment,
    Button,
    List,
    ListItem,
    HeaderContent,
    ListHeader,
    Card,
    Image,
    GridColumn,
    ListDescription,
    CardGroup
} from 'semantic-ui-react'
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },

}

export const AIHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("AIHeaderContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};



export function AI() {
    const [isActive, setIsActive] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 220  }}
                vertical>
                <AIHeading />
            </Segment>

            <Segment vertical >
                <Header
                    as='h2'
                    content={t("InvestInKnowledge")}
                    style={style.h3}
                    textAlign='center'
                />
                <Container>
                    <CardGroup centered style={{ marginBottom: "2em"}}>
                        <Card link >
                            <Image src='/images/AI.jpg' wrapped ui={false} />
                            <Header as='h3'style={{ margin: "1em"}}>
                                {t("TitleArticle1")}
                            </Header>
                            <HeaderContent textAlign="justified">
                                {t("DescriptionArticle1")}
                            </HeaderContent>
                            <Container style={{ marginBottom: "1em", paddingLeft: "1em" }}>
                                <Button secondary onClick={() => navigate("/education/AI/What-is-AI")}>{t("ReadMoreButton")}</Button>
                            </Container>
                            {isActive && (
                                <Container>
                                    <Header>
                                        {t("SubTitleArticle1")}
                                    </Header>
                                    <HeaderContent>
                                        {t("DescriptionSubTArticle1")}
                                    </HeaderContent>

                                    <ListHeader as="h4">
                                        {t("HeaderTypesAI")}
                                    </ListHeader>
                                    <HeaderContent>
                                        {t("DescriptionTypesAI")}
                                    </HeaderContent>
                                    <List ordered>
                                        <ListItem >
                                            <ListHeader>{t("TitleItem1TypesAI")}</ListHeader>
                                            <ListItem as='li' value='-'>
                                                {t("Item1TypesAI")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("TitleItem2TypesAI")}</ListHeader>
                                            <ListItem as='li' value='*'>
                                                {t("Item2TypesAI")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("TitleItem3TypesAI")}</ListHeader>
                                            <ListItem as='li' value='*'>
                                                {t("Item3TypesAI")}
                                            </ListItem>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("HowAIWorks")}
                                    </ListHeader>
                                    <Container textAlign="justified">
                                        <p>{t("AIdescriptionParagraph")}</p>
                                    </Container>

                                    <List bulleted>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("MacchineLearning")}</b>
                                                {t("DescriptionMachineLearning")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("NeuralTitle")}</b>
                                                {t("NeuralDescription")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("ComputerVisionTitle")}</b>
                                                {t("ComputerVisionItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("AIAplicationTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionAIAplication")}</p>
                                    <List bulleted>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("HealthcareBold")}</b>
                                                {t("HealthcareItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("FinanceBold")}</b>
                                                {t("FinanceItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("RetailBold")}</b>
                                                {t("RetailItem")}
                                            </ListItem>
                                            <ListItem >
                                                <b>{t("ManufacturingBold")}</b>
                                                {t("ManufacturingItem")}
                                            </ListItem>
                                            <ListItem >
                                                <b>{t("TransportationBold")}</b>
                                                {t("TransportationItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <Header>{t("AIFutureTitle")}</Header>
                                    <Container textAlign="justified">
                                        <p>{t("AIfutureText1")}</p>
                                        <p>{t("AIfutureText2")}</p>
                                        <p>{t("AIfutureText3")}</p>
                                    </Container>
                                </Container>
                            )}
                        </Card>

                        <Card link>
                            <Image rounded src='/images/market.jpg' />
                            <Header as='h3'style={{ margin: "1em"}}>
                                {t("TitleArticle2")}
                            </Header>
                            <HeaderContent textAlign="justified">
                                {t("DescriptionArticle2")}
                            </HeaderContent>
                            <Container style={{ marginBottom: "1em", paddingLeft: "1em" }}>
                                <Button secondary onClick={() => navigate("/education/AI/Stock-Market-in-Poland")}>{t("ReadMoreButton")}</Button>
                            </Container>
                            {isActive && (

                                <Container>
                                    <Header>
                                        {t("SubTitleArticle2")}
                                    </Header>
                                    <HeaderContent>
                                        {t("SubTitleArticle2Description")}
                                    </HeaderContent>

                                    <ListHeader as="h4">
                                        {t("StructureOfStockTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionofStock")}</p>

                                    <List ordered>
                                        <ListItem>
                                            <ListHeader>{t("MainMarketBold")} </ListHeader>
                                            <p>{t("MainMarketItem1")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("NewConnectBold")} </ListHeader>
                                            <p>{t("NewConnectItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("CatalystBold")}</ListHeader>
                                            <p>{t("CatalystItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("DerivativesBold")} </ListHeader>
                                            <p>{t("DerivativesItem")}</p>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("KeyPlayersIndicesTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionofIndices")}</p>
                                    <List ordered>
                                        <ListItem>
                                            <ListHeader>{t("WIGBold")} </ListHeader>
                                            <p>{t("WIGItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("WIG2OBold")} </ListHeader>
                                            <p>{t("WIG2OItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("WIG40Bold")}</ListHeader>
                                            <p>{t("WIG40Item")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("BlueChipBold")} </ListHeader>
                                            <p>{t("BlueChipItem")}</p>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("TrendAndDevelopmentsTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionofTrends")}</p>
                                    <List ordered>
                                        <ListItem>
                                            <ListHeader>{t("TechnologicalBold")} </ListHeader>
                                            <p>{t("TechnologicalItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("ForeignInvestmentBold")} </ListHeader>
                                            <p>{t("ForeignInvestmentItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("ESGBold")}</ListHeader>
                                            <p>{t("ESGItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("ReformsBold")} </ListHeader>
                                            <p>{t(" ReformsItem")}</p>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("ProspectsTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionofProspects")}</p>
                                    <List ordered>
                                        <ListItem>
                                            <ListHeader>{t("MarketVolatilityBold")} </ListHeader>
                                            <p>{t("MarketVolatilityltem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("CorporateGovernanceBold")} </ListHeader>
                                            <p>{t("CorporateGovernanceItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("LiquidityIssuesBold")}</ListHeader>
                                            <p>{t("LiquidityIssuesItem")}</p>
                                        </ListItem>
                                        <ListDescription>{t("EndTextDescription")}</ListDescription>
                                    </List>
                                    <Container textAlign="justified">
                                        <p>{t("EndArticle2Text1")}</p>
                                        <p>{t("EndArticle2Text2")}</p>
                                    </Container>
                                </Container>
                            )}
                        </Card>

                        <Card link>
                            <Image rounded src='/images/RiskManagement.jpg' />
                            <Header as="h3" style={{ margin: "1em"}}>
                                {t("Article3Title")}
                            </Header>
                            <HeaderContent textAlign="justified">
                                {t("ShortDescriptionArticle3")}
                            </HeaderContent>
                            <Container style={{ marginBottom: "1em", paddingLeft: "1em" }}>
                                <Button secondary onClick={() => navigate("/education/AI/Risk-Management-Essential-Strategies-for-Business-Resilience")}>{t("ReadMoreButton")}</Button>
                            </Container>
                            {isActive && (
                                <Container>
                                    <ListHeader as="h4">
                                        {t("Article3Subtitle")}
                                    </ListHeader>
                                    <p>{t("SubtitleShortDescriptionArticle3")}</p>

                                    <List ordered>
                                        <ListItem>
                                            <ListHeader>{t("RiskManagementTitle")} </ListHeader>
                                            <p>{t("RiskManagementShortText")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("RiskIdentificationBold")} </ListHeader>
                                            <p>{t("RiskIdentificationItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("RiskEvaluationBold")}</ListHeader>
                                            <p>{t("RiskEvaluationItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("RiskTreatmentBold")} </ListHeader>
                                            <p>{t("RiskTreatmentItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("RiskMonitoringReviewBold")} </ListHeader>
                                            <p>{t("RiskMonitoringReviewItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("CommunicationConsultationBold")} </ListHeader>
                                            <p>{t("CommunicationConsultationItem")}</p>
                                        </ListItem>
                                    </List>
                                    <ListHeader as="h4">
                                        {t("RiskTypesTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionRiskTypes")}</p>
                                    <List ordered>
                                        <ListItem >
                                            <ListHeader>{t("FinancialRisksBold")}</ListHeader>
                                            <ListItem >
                                                {t("FinancialRisksItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("OperationalRisksBold")}</ListHeader>
                                            <ListItem>
                                                {t("OperationalRisksItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("StrategicRisksBold")}</ListHeader>
                                            <ListItem>
                                                {t("StrategicRisksItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("ComplianceRisksBold")}</ListHeader>
                                            <ListItem>
                                                {t("ComplianceRisksItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("ReputationalRisksBold")}</ListHeader>
                                            <ListItem>
                                                {t("ReputationalRisksItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <ListHeader as="h4">
                                        {t("BenefitsOfRiskManagementTitle")}
                                    </ListHeader>
                                    <p>{t("BenefitsOfRiskManagementDescription")}</p>
                                    <List bulleted>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("EnhancedDecisionMakingBold")}</b>
                                                {t("EnhancedDecisionMakingItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("IncreasedResilienceBold")}</b>
                                                {t("IncreasedResilienceItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("CostSavingsBold")}</b>
                                                {t("CostSavingsItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("ImprovedStakeholderConfidenceBold")}</b>
                                                {t("ImprovedStakeholderConfidenceItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("CompetitiveAdvantageBold")}</b>
                                                {t("CompetitiveAdvantageItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <ListHeader as="h4">
                                        {t("ChallengesInRiskManagementTitle")}
                                    </ListHeader>
                                    <p>{t("ChallengesInRiskManagementDescription")}</p>
                                    <List bulleted>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("ComplexityBold")}</b>
                                                {t("ComplexityItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("DataAndInformationBold")}</b>
                                                {t("DataAndInformationItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("ResourceConstraintsBold")}</b>
                                                {t("ResourceConstraintsItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("CulturalBarriersBold")}</b>
                                                {t("CulturalBarriersItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <ListHeader as="h4">
                                        {t("FutureTrendsInRiskManagementTitle")}
                                    </ListHeader>
                                    <p>{t("FutureTrendsInRiskManagementDescription")}</p>
                                    <List bulleted>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("TechnologyIntegrationBold")}</b>
                                                {t("TechnologyIntegrationItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("CybersecurityBold")}</b>
                                                {t("CybersecurityItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("SustainabilityESGBold")}</b>
                                                {t("SustainabilityESGItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("RegulatoryChangesBold")}</b>
                                                {t("RegulatoryChangesItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("GlobalizationBold")}</b>
                                                {t("GlobalizationItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <ListDescription>{t("ConclusionArt3")}</ListDescription>
                                </Container>
                            )}
                        </Card>

                        <Card link>
                            <Image rounded src='/images/StockInvestment.jpg' />
                            <Header as="h3" style={{ margin: "1em"}}>
                                {t("Article4Title")}
                            </Header>
                            <HeaderContent textAlign="justified">
                                {t("ShortDescriptionArticle4")}
                            </HeaderContent>
                            <Container style={{ marginBottom: "1em", paddingLeft: "1em" }}>
                                <Button secondary onClick={() => navigate("/education/AI/Investing-in-the-Stock-Market-A-Comprehensive-Guide")}>{t("ReadMoreButton")}</Button>
                            </Container>
                            {isActive && (
                                <Container>
                                    <ListHeader as="h4">
                                        {t("Article4Subtitle")}
                                    </ListHeader>
                                    <p>{t("SubtitleShortDescriptionArt4")}</p>

                                    <List ordered>
                                        <ListItem>
                                            <ListHeader>{t("Art4SubTitle")} </ListHeader>
                                            <p>{t("Art4ShortSubtitleText")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("HighReturnsBold")} </ListHeader>
                                            <p>{t("HighReturnsItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("DividendIncomeBold")}</ListHeader>
                                            <p>{t("DividendIncomeItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("RiskTreatmentBold")} </ListHeader>
                                            <p>{t("RiskTreatmentItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("DiversificationBold")} </ListHeader>
                                            <p>{t("DiversificationItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("OwnershipInfluenceBold")} </ListHeader>
                                            <p>{t("OwnershipInfluenceItem")}</p>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("StartInvestingTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionStartInvesting")}</p>
                                    <List ordered>
                                        <ListItem >
                                            <ListHeader>{t("FinancialGoalsBold")}</ListHeader>
                                            <ListItem >
                                                {t("FinancialGoalItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("EstablishBudgetBold")}</ListHeader>
                                            <ListItem>
                                                {t("EstablishBudgetItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("BrokerageAccountBold")}</ListHeader>
                                            <ListItem>
                                                {t("BrokerageAccountItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("ResearchSelectStocksBold")}</ListHeader>
                                            <ListItem>
                                                {t("ResearchSelectStocksItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("DiversifyPortfolioBold")}</ListHeader>
                                            <ListItem>
                                                {t("DiversifyPortfolioItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("InvestRegularlyBold")}</ListHeader>
                                            <ListItem>
                                                {t("InvestRegularlyItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("InvestmentStrategiesTitle")}
                                    </ListHeader>
                                    <p>{t("InvestmentStrategiesShortDescription")}</p>
                                    <List ordered>
                                        <ListItem >
                                            <ListHeader>{t("ValueInvestingBold")}</ListHeader>
                                            <ListItem >
                                                {t("ValueInvestingtem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("GrowthInvestingBold")}</ListHeader>
                                            <ListItem>
                                                {t("GrowthInvestingItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("IncomeInvestingBold")}</ListHeader>
                                            <ListItem>
                                                {t("IncomeInvestingItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("IndexInvestingBold")}</ListHeader>
                                            <ListItem>
                                                {t("IndexInvestingItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("ActiveTradingBold")}</ListHeader>
                                            <ListItem>
                                                {t("ActiveTradingItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("ManagingRisksTitle")}
                                    </ListHeader>
                                    <p>{t("ManagingRisksShortDescription")}</p>
                                    <List ordered>
                                        <ListItem >
                                            <ListHeader>{t("DiversificationListBold")}</ListHeader>
                                            <ListItem >
                                                {t("DiversificationListItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("RiskToleranceBold")}</ListHeader>
                                            <ListItem>
                                                {t("RiskToleranceItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("RegularMonitoringBold")}</ListHeader>
                                            <ListItem>
                                                {t("RegularMonitoringBold")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("AvoidEmotionalDecisionBold")}</ListHeader>
                                            <ListItem>
                                                {t("AvoidEmotionalDecisionItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListHeader>{t("EducateYourselfBold")}</ListHeader>
                                            <ListItem>
                                                {t("EducateYourselfItem")}
                                            </ListItem>
                                        </ListItem>

                                        <ListHeader as="h4">
                                            {t("MistakesAvoidTitle")}
                                        </ListHeader>
                                        <p>{t("ShortDescriptionMistakesAvoid")}</p>
                                        <List ordered>
                                            <ListItem >
                                                <ListHeader>{t("LackResearchBold")}</ListHeader>
                                                <ListItem >
                                                    {t("LackResearchItem")}
                                                </ListItem>
                                            </ListItem>
                                            <ListItem >
                                                <ListHeader>{t("OvertradingBold")}</ListHeader>
                                                <ListItem>
                                                    {t("OvertradingItem")}
                                                </ListItem>
                                            </ListItem>
                                            <ListItem >
                                                <ListHeader>{t("IgnoringDiversBold")}</ListHeader>
                                                <ListItem>
                                                    {t("IgnoringDiversItem")}
                                                </ListItem>
                                            </ListItem>
                                            <ListItem >
                                                <ListHeader>{t("MarketTimingBold")}</ListHeader>
                                                <ListItem>
                                                    {t("MarketTimingItem")}
                                                </ListItem>
                                            </ListItem>
                                            <ListItem >
                                                <ListHeader>{t("NeglectingFeesBold")}</ListHeader>
                                                <ListItem>
                                                    {t("NeglectingFeesItem")}
                                                </ListItem>
                                            </ListItem>

                                        </List>
                                        <Header>{t("ConclusionTitleArt4")}</Header>
                                        <p>{t("ConclusionDescriptionArt4")}</p>
                                    </List>
                                </Container>
                            )}
                        </Card>
                        <Card link>
                            <Image rounded src='/images/AlgoritmTrading.jpg' />
                            <Header as="h3" style={{ margin: "1em"}}>
                                {t("Article5Title")}
                            </Header>
                            <HeaderContent textAlign="justified">
                                {t("ShortDescriptionArticle5")}
                            </HeaderContent>
                            <Container style={{ marginBottom: "1em", paddingLeft: "1em" }}>
                                <Button secondary onClick={() => navigate("/education/AI/Algorithmic-Trading-The-Future-of-Financial-Markets")}>{t("ReadMoreButton")}</Button>
                            </Container>
                            {isActive && (
                                <Container>
                                    <ListHeader as="h3">
                                        {t("Article5Title2")}
                                    </ListHeader>
                                    <p>{t("ShortDescription2Article5")}</p>
                                    <ListHeader as="h4">
                                        {t("Article5Title3")}
                                    </ListHeader>
                                    <p>{t("ShortDescription3Article5")}</p>

                                    <ListHeader>{t("Art5ListTitle")} </ListHeader>
                                    <p>{t("ShortDescription4Article5")}</p>

                                    <List ordered>
                                        <ListItem>
                                            <ListHeader>{t("MarketMakingBold")} </ListHeader>
                                            <p>{t("MarketMakingItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("StatisticalArbitrageBold")}</ListHeader>
                                            <p>{t("StatisticalArbitrageItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("TrendFollowingBold")} </ListHeader>
                                            <p>{t("TrendFollowingItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("MeanReversionBold")} </ListHeader>
                                            <p>{t("MeanReversionItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("EventDrivenStrategiesBold")} </ListHeader>
                                            <p>{t("EventDrivenStrategiesItem")}</p>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("AdvantagesAlgorithmicTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionAdvantagesAlgorithmic")}</p>
                                    <List bulleted>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("SpeedEfficiencyBold")}</b>
                                                {t("SpeedEfficiencyItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("ReducedEmotionalBiasBold")}</b>
                                                {t("ReducedEmotionalBiasItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("ScalabilityBold")}</b>
                                                {t("ScalabilityItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("EnhancedBacktestingBold")}</b>
                                                {t("EnhancedBacktestingItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("ChallengesRisksTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionChallengesRisks")}</p>
                                    <List bulleted>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("MarketVolatilityBold1")}</b>
                                                {t("MarketVolatilityItem1")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("TechnicalFailuresBold")}</b>
                                                {t("TechnicalFailuresItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("RegulatoryScrutinyBold")}</b>
                                                {t("RegulatoryScrutinyItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("ComplexityTransparencyBold")}</b>
                                                {t("ComplexityTransparencyItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <ListHeader as="h4">
                                        {t("AlgorithmicTradingFutureTitle")}
                                    </ListHeader>
                                    <p>{t("ShortDescriptionAlgorithmicTradingFutures")}</p>
                                    <List bulleted>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("AIMachineLearningBold")}</b>
                                                {t("AIMachineLearningItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("QuantumComputingBold")}</b>
                                                {t("QuantumComputingItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("BlockchainBold")}</b>
                                                {t("BlockchainItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("SustainableEthicalTradingBold")}</b>
                                                {t("SustainableEthicalTradingItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <ListHeader>{t("ConclusionArt5")}</ListHeader>
                                    <ListDescription>{t("ConclusionArt5Text")}</ListDescription>
                                </Container>
                            )}
                        </Card>
                        <Card link>
                            <Image rounded src='/images/AIStockMarket.jpg' />
                            <Header as="h3"style={{ margin: "1em" }}>
                                {t("Article6Title")}
                            </Header>
                            <HeaderContent textAlign="justified">
                                {t("ShortDescriptionArticle6")}
                            </HeaderContent>
                            <Container style={{ marginBottom: "1em", paddingLeft: "1em" }}>
                                <Button secondary onClick={() => navigate("/education/AI/AI-and-the-Stock-Market-Transforming-Investment-Strategies")}>{t("ReadMoreButton")}</Button>
                            </Container>
                            {isActive && (
                                <Container>
                                    <ListHeader as="h4">
                                        {t("Article6Title2")}
                                    </ListHeader>
                                    <p>{t("ShortDescription2Article6")}</p>
                                    <List ordered>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("AnalyzeHistoricalDataBold")}</b>
                                                {t("AnalyzeHistoricalDataItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("MonitorRealTimeMarketConditions")}</b>
                                                {t("MonitorRealTimeMarketConditionsItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("PredictStockPricesBold")}</b>
                                                {t("PredictStockPricesItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("ExecuteTradesBold")}</b>
                                                {t("ExecuteTradesItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>

                                    <ListHeader as="h4">
                                        {t("BenefitsAIStockTradingTitle")}
                                    </ListHeader>
                                    <p>{t("BenefitsAIStockTradingDescription")}</p>
                                    <List ordered>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("ImprovedAccuracyBold")}</b>
                                                {t("ImprovedAccuracyItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("SpeedBold")}</b>
                                                {t("SpeedItem")}
                                            </ListItem>
                                        </ListItem>

                                        <ListItem >
                                            <ListItem >
                                                <b>{t("DataDrivenDecisionsBold")}</b>
                                                {t("DataDrivenDecisionsItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("RiskManagementBold")}</b>
                                                {t("RiskManagementItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("EfficiencyBold")}</b>
                                                {t("EfficiencyItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <ListHeader as="h4">
                                        {t("ChallengesAndConsiderationsTitle")}
                                    </ListHeader>
                                    <p>{t("ChallengesAndConsiderationsDescription")}</p>
                                    <List ordered>
                                        <ListItem>
                                            <ListHeader>{t("Complexity2Item")}</ListHeader>
                                            <p>{t("Complexity2Item")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("MarketVolatility2Bold")} </ListHeader>
                                            <p>{t("MarketVolatility2Item")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("DataQualityBold")} </ListHeader>
                                            <p>{t("DataQualityItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("RegulatoryConcernsBold")} </ListHeader>
                                            <p>{t("RegulatoryConcernsItem")}</p>
                                        </ListItem>
                                        <ListItem>
                                            <ListHeader>{t("EthicalConsiderationsBold")} </ListHeader>
                                            <p>{t("EthicalConsiderationsItem")}</p>
                                        </ListItem>
                                    </List>
                                    <ListHeader as="h4">
                                        {t("FutureProspectsTitle")}
                                    </ListHeader>
                                    <p>{t("FutureProspectsDescription")}</p>
                                    <List ordered>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("EnhancedPredictiveModelsBold")}</b>
                                                {t("EnhancedPredictiveModelsItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("BlockchainIntegrationBold")}</b>
                                                {t("BlockchainIntegrationItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("PersonalizedInvestmentStrategiesBold")}</b>
                                                {t("PersonalizedInvestmentStrategiesItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem >
                                            <ListItem >
                                                <b>{t("CollaborationWithHumanTradersBold")}</b>
                                                {t("CollaborationWithHumanTradersItem")}
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <ListDescription>{t("AIStockMarketConclusion")}</ListDescription>
                                    <ListDescription>{t("AIStockMarketConclusionText")}</ListDescription>
                                </Container>
                            )}
                        </Card>

                    </CardGroup>
                </Container>
            </Segment>
        </>
    );
}
