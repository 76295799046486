import React from "react";
import {
  Grid,
  Icon,
  Table,
  Container,
  Header,
  ListDescription,
  List,
  ListItem,
  Segment,
} from "semantic-ui-react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { request } from "services/axios_interceptor";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { UserContext } from "App";
import { useContext } from "react";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

export const StocksHeader = function () {
  const { t } = useTranslation();
  return (
    <Container text>
      <Header
        as="h1"
        content={t("StocksHeaderContent")}
        inverted
        style={{
          fontSize: "3em",
          fontWeight: "normal",
          marginBottom: 0,
          marginTop: "1em",
        }}
      />
    </Container>
  )
};

export const Stocks = ({ setShowLogin }: { setShowLogin: (show: any) => void }) => {
  const navigate = useNavigate();
  const [stocks, setStocks] = useState<any[]>([]);
  const { t } = useTranslation();
  const shareNames = useContext(UserContext);

  let user: any;
  user = shareNames;

  useEffect(() => {

    request("GET", "/recommendations/shares", {})
      .then((response) => {
        setStocks(response.data.data);

      })
      .catch((e) =>
        toast.error(
          t("serverErrorMessage"),
        )
      );

    window.scrollTo(0, 0);
  }, []);
  console.log(user);
  if (!user.loggedIn) {
    setShowLogin(true);
    return (<></>);
  }
  if (stocks.length > 0) {
    stocks.forEach((item, idx) => {
      for (let i = 0; i < user.shareNames.length; i++) {
        if (item.instrumentName === user.shareNames[i].code) {
          stocks[idx].shareName = user.shareNames[i].name;
        }
      }
    }); console.log(stocks);
  }

  const navigateToInstrumentHistory = (instrumentName: string) => {
    navigate(`/recommendation/history/${instrumentName}`);
  };

  return (
    <>
      <Segment
        inverted
        textAlign="center"
        style={{ minHeight: 250, padding: "1em 0em" }}
        vertical
      >
        <StocksHeader />
      </Segment>
      <Container style={{ margin: "2rem" }}>
        {stocks.length > 0 && (
          <Grid.Column style={{ padding: "2rem" }} width={8}>
            <Segment stackable verticalAlign="middle">
              <Header as='h3' >{t("StocksHeaderDescription")}</Header>
              <ListDescription ><Icon name="arrow up" color="green" /> {t("StocksHeaderDescription1")}</ListDescription>
              <List bulleted style={{ textAlign: "middle" }} >
                <ListItem style={{ marginLeft: "2em" }}>{t("PercentageDescription1")}</ListItem>
                <ListItem style={{ marginLeft: "2em" }}>{t("PercentageDescription2")}</ListItem>
                <ListItem style={{ marginLeft: "2em" }}>{t("PercentageDescription3")}</ListItem>
              </List>
              <ListDescription style={{ margin: "1.2em" }}>{t("StocksHeaderDescription2")}</ListDescription>
              {/* <ListDescription>
                <GridRow>
                  <GridColumn><Icon name="warning" color="red" /> <span style={{ fontWeight: "bold" }}>{t("warningWord")}</span>
                    <span>{t("StocksWarning")}</span></GridColumn>
                </GridRow>
              </ListDescription> */}
            </Segment>
            <Table celled content='Responsive Table'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell rowSpan="3">{t("TableHeaderInstrument")}</Table.HeaderCell>
                  <Table.HeaderCell colSpan="3">{t("TableHeaderRecommendations")}</Table.HeaderCell>
                  <Table.HeaderCell rowSpan="2"></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell style={{ borderLeft: "1px solid rgba(34,36,38,.1)" }}>{t("TableHeaderCellfirstPeriod")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("TableHeaderCellsecondPeriod")}</Table.HeaderCell>
                  <Table.HeaderCell>{t("TableHeaderCellthridPeriod")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body content='Responsive Table'>
                {stocks &&
                  stocks.map((recommendation: any) => {
                    return (
                      <Table.Row>
                        <Table.Cell>{recommendation.shareName}</Table.Cell>
                        <Table.Cell>
                          {recommendation.recommendations[0] ? (
                            recommendation.recommendations[0].recommendation ? (
                              <>
                                {t("YesWord")} <Icon name="arrow up" color="green" />
                              </>
                            ) : (
                              "-"
                            )
                          ) : (
                            "-"
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {recommendation.recommendations[1] ? (
                            recommendation.recommendations[1].recommendation ? (
                              <>
                                {t("YesWord")}<Icon name="arrow up" color="green" />
                              </>
                            ) : (
                              "-"
                            )
                          ) : (
                            "-"
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {recommendation.recommendations[2] ? (
                            recommendation.recommendations[2].recommendation ? (
                              <>
                                {t("YesWord")}<Icon name="arrow up" color="green" />
                              </>
                            ) : (
                              "-"
                            )
                          ) : (
                            "-"
                          )}
                        </Table.Cell>
                        <Table.Cell
                          onClick={() =>
                            navigateToInstrumentHistory(
                              recommendation.instrumentName
                            )
                          }
                        >
                          <S.StyledIcon
                            disabled
                            name="angle right"
                            size="big"
                            style={{ marginRight: "-25px" }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </Grid.Column>
        )}
        <ListDescription stackable verticalAlign="middle" style={{ marginLeft: "1.5em", marginRight: "1.11em" }} ><Icon name="warning" color="red" /> <span style={{ fontWeight: "bold" }}>{t("warningWord")}</span>
          <span>{t("StocksWarning")}</span></ListDescription>
      </Container>
    </>
  );
};

const S = {
  StyledIcon: styled(Icon)`
    max-width: 450px;
  `,
};
