import React, { useState, useEffect } from "react";
import {
    Container,
    Segment,
    Modal,
    Form,
    Grid,
    Button
} from "semantic-ui-react";
import { request, setAuthToken } from "services/axios_interceptor";
import toast from "react-hot-toast";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'
import styled from "styled-components";
import { useParams } from "react-router-dom";

type changePassword = {
    password: string;
    resetPasswordCode: string;

};

export default function NewPassword() {
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [openPopup, setOpenPopup] = useState(true);
    const urlParams = useParams();

    const resetPassword = () => {

        if (newPassword === confirmNewPassword) {
            request("POST", `/reset-password`, {
                password: newPassword,
                resetPasswordCode: urlParams.codeValidation
            })
                .then((response) => {
                    //setAccountConfirmationPage(userConfirmation);
                    toast.success(t("PasswordChangedMessage"));
                    setOpenPopup(false);
                })
                .catch((e) =>
                    toast.error(
                        t("serverErrorMessage"),
                    )
                );
        } else {
            toast.error(
                t("equalPasswordMessage"),
            )
        }
    }
    return (
        <Container>
            <Modal open={openPopup} size="tiny">
                <Modal.Header>{t("ChooseNewPasswordHeader")}</Modal.Header>
                <Modal.Content>
                    <Grid textAlign="center" verticalAlign="middle">
                        <S.StyledColumn>
                            <Form size="large" onSubmit={() => resetPassword()}>
                                <Segment stacked>
                                    <Form.Input
                                        fluid
                                        icon="lock"
                                        iconPosition="left"
                                        placeholder={t("NewPasswordPlaceholder")}
                                        type="password"
                                        onChange={(e) =>
                                            setNewPassword(e.target.value)
                                        }
                                    />
                                    <Form.Input
                                        fluid
                                        icon="lock"
                                        iconPosition="left"
                                        placeholder={t("ConfirmNewPasswordPlaceholder")}
                                        type="password"
                                        onChange={(e) =>
                                            setConfirmNewPassword(e.target.value)
                                        }
                                    />
                                </Segment>
                            </Form>

                        </S.StyledColumn>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" onClick={() => setOpenPopup(false)}>
                        {t("resestPasswordCancelButton")}
                    </Button>
                    <Button
                        color="green"
                        onClick={() => resetPassword()}
                        type="reset"
                    >
                        {t("resestPasswordSubmitButton")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Container>
    );


}
const S = {
    Link: styled.span`
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    `,
    StyledColumn: styled(Grid.Column)`
      max-width: 450px;
    `,
};