import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Segment,
  CardHeader,
  CardContent,
  Card,
  CardMeta,
  List,
  ListItem,
  ListHeader,
  Icon,
} from "semantic-ui-react";
import { request } from "services/axios_interceptor";
import toast from "react-hot-toast";
import { UserContext } from "App";
import { useContext } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

type typesOfSubscription = [{
  description: string;
  id: 0;
  name: string,
  price: string;
}];

export const SubscriptionTypesHeader = function () {
  const { t } = useTranslation();

  return (
    <Container text>
      <Header
        as="h1"
        content={t("HeaderSubscriptionTypePage")}
        inverted
        style={{
          fontSize: "3em",
          fontWeight: "normal",
          marginBottom: 0,
          marginTop: "1em",
        }}
      />
    </Container>
  )
};
export const SubscriptionTypes = function () {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const urlTypesParameters = useParams();
  const colors = {
    functionality: '#fce895',
    basic: '#73aee6',
    premium: '#825aad',
    enterprise: '#f59d51',
    packages: "#fcf9e6"
  }
  const [subscriptionTypes, setSubscriptionTypes] = useState<typesOfSubscription>([{
    description: "",
    id: 0,
    name: "",
    price: ""
  }]);
  if (!user.loggedIn) {
    navigate("/offer");
  }
const startPayment = (paymentId: any ) => {
  request("POST", `/subscription/start-payment`, {
    subscriptionTypeId:paymentId
  })
      .then((response) => {
        window.location.href=(response.data.data.paymentUrl);
      })
      .catch((e) =>
        toast.error(
          t("serverErrorMessage"),

        )
      );
}

  useEffect(() => {
    request("GET", `/subscription/types/${urlTypesParameters.type}`, {})
      .then((response) => {
        setSubscriptionTypes(response.data.data);
        console.log(response.data.data);
      })
      .catch((e) =>
        toast.error(
          t("serverErrorMessage"),

        )
      );
  }, [urlTypesParameters.type])

  return (
    <>
      <Segment
        inverted
        textAlign="center"
        style={{ minHeight: 250, padding: "1em 0em" }}
        vertical
        stackable
      >
        <SubscriptionTypesHeader />
      </Segment>
      {user.loggedIn && (
        <Container stackable verticalAlign="middle" style={{ margin: "2rem" }} content='Container' textAlign="left">

          {urlTypesParameters.type === "basic" && (
            <Container stackable verticalAlign="left" style={{ margin: "2rem" }} content='Container' >
              <List content='Responsive Item' floated="left" >
                <Header as='h2' >{t("BasicTypeSubscription")}</Header>
                <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality1")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality2")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality3")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality4")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality5")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="right triangle" />{t("tCFunctionality6")}</ListHeader></ListItem>
              </List>
            </Container>)}
          {urlTypesParameters.type === "premium" && (
            <Container stackable centered verticalAlign="middle" style={{ margin: "2rem" }} content='Container' >
              <List content='Responsive Item'floated="left">
                <Header as='h2' >{t("PremiumTypeSubscription")}</Header>
                <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality1")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality2")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality3")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality4")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality5")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality6")}</ListHeader></ListItem>
                <ListItem style={{ lineHeight: '2em' }}><ListHeader><Icon className="right triangle" />{t("tCFunctionality7")}</ListHeader></ListItem>
              </List>
            </Container>)}
          <Container stackable verticalAlign="middle">
            <Grid style={{ marginTop: "2em", marginButtom: "2em" }} columns="equal" stackable > 
              <GridRow >
                {subscriptionTypes.map((type) => {
                  return (
                    <GridColumn >
                      <Card centered key={type.id}>
                        <CardHeader as="h3" textAlign="center" style={{ marginTop: "1em", fontSize: "1.3em", minHeight: "1em" }}>{type.name}</CardHeader>
                        <CardMeta textAlign="center">{type.price} PLN</CardMeta>
                        {/* <CardDescription style={{ marginBottom: "1em" }} textAlign="center">{type.description}</CardDescription> */}
                        <CardContent style={{ marginTop: "1em" }}>
                          <Button 
                          fluid 
                          positive
                          onClick={() => startPayment(type.id)}
                          >
                            {t("BuyButton")}</Button>
                        </CardContent>
                      </Card>
                    </GridColumn>
                  )
                })}
              </GridRow>
            </Grid>
          </Container>
          
          <Container stackable style={{ margin: "12em" }} >
              {urlTypesParameters.type === "premium" && (
                <Button primary onClick={() => navigate("/payments/SubscriptionTypes/basic")}>{t("ChangeBasicButton")}</Button>
              )}
            </Container>

            <Container stackable style={{ margin: "12em" }} >
              {urlTypesParameters.type === "basic" && (
                <Button primary onClick={() => navigate("/payments/SubscriptionTypes/premium")}>{t("ChangePremiumButton")}</Button>
              )}
            </Container>
         
        </Container>
      )}
    </>
  );

}
