import React from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import {
  Button,
  Form,
  Grid,
  Header,
  Modal,
  Segment,
  Checkbox,
  FormField
} from "semantic-ui-react";
import { isValidEmail, request, setAuthToken } from "services/axios_interceptor";
import styled from "styled-components";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'

type SignupForm = {
  agreements: any;
  companyName: string;
  email: string;
  firstName: string;
  isCompany: boolean;
  lastName: string;
  nip: string;
  password: string;

};

function SignupIndividualModal({
  showSignup,
  closeModal,
}: {
  showSignup: boolean;
  closeModal: () => void;
}) {
  const { t } = useTranslation();
  const [signUpForm, setSignUpForm] = useState<SignupForm>({
    agreements: true,
    companyName: "",
    email: "",
    firstName: "",
    isCompany: false,
    lastName: "",
    nip: "",
    password: ""
  });
  console.log(t("CreateAccountIndivSuccesMessage"));
  const submit = () => {

    let userDataForm = {
      agreements: signUpForm.agreements,
      companyName: signUpForm.companyName,
      email: signUpForm.email,
      firstName: signUpForm.firstName,
      isCompany: signUpForm.isCompany,
      lastName: signUpForm.lastName,
      nip: signUpForm.nip,
      password: signUpForm.password
    }
    if (isValidEmail(signUpForm.email) === false) {
      toast.error(
        (t("CreateAccountIndivEmailIsValid")),
      );
      return;
    }

    request("POST", "/user/create-application", userDataForm)
      .then((response) => {

        setAuthToken(response.data.token);
        toast.success(t("CreateAccountIndivSuccesMessage"));
        closeModal();
      })
      .catch((e) =>
        toast.error(
          t("serverErrorMessage"),
        )
      );
  };

  return (
    <Modal onClose={() => closeModal()} open={showSignup} size="tiny">
      <Modal.Header>{t("SignUpModalHeader")}</Modal.Header>
      <Modal.Content>
        <Grid textAlign="center" verticalAlign="middle">
          <S.StyledColumn>
            <Header as="h2" color="black" textAlign="center">
              {t("ModalHeaderContent")}
            </Header>
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  fluid
                  placeholder={t("FirstNameModal")}
                  onChange={(e) =>
                    setSignUpForm((prevState) => ({
                      ...prevState,
                      firstName: e.target.value,
                    }))
                  }
                />
                <Form.Input
                  fluid
                  placeholder={t("LastNameModal")}
                  onChange={(e) =>
                    setSignUpForm((prevState) => ({
                      ...prevState,
                      lastName: e.target.value,
                    }))
                  }
                />
                <Form.Input
                  fluid
                  placeholder={t("EmailModal")}
                  onChange={(e) =>
                    setSignUpForm((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }))
                  }
                />
                <Form.Input
                  fluid
                  placeholder={t("PasswordModal")}
                  type="password"
                  onChange={(e) =>
                    setSignUpForm((prevState) => ({
                      ...prevState,
                      password: e.target.value,
                    }))
                  }
                />
                <FormField>
                  <Checkbox label= {<label>{t("CheckboxTermsAcceptance")}  <a href="/documentation/Terms&Conditions">{t("TermsAcceptance")}</a> {t("andWordTermAcceptance")} <a href="/documentation/PrivacyPolicy">{t("PrivacyPolicy")}</a> </label> }
                    onChange={(e, data) =>
                      setSignUpForm((prevState) => ({
                        ...prevState,
                        agreements: data.checked,
                      }))
                    } />
                </FormField>
              </Segment>
            </Form>
          </S.StyledColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => closeModal()}>
          {t("CancelSignUpForm")}
        </Button>
        <Button
          color="green"
          onClick={() => submit()}
          disabled={
            signUpForm.firstName.length === 0 ||
            signUpForm.lastName.length === 0 ||
            signUpForm.email.length === 0 ||
            signUpForm.password.length === 0
          }
        >
          {t("SubmitSignUpForm")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
const S = {
  StyledColumn: styled(Grid.Column)`
    max-width: 450px;
  `,
};

export default SignupIndividualModal;
