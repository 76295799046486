import React from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { Button, 
  Form, 
  Grid, 
  GridColumn,
  GridRow, 
  Modal, 
  Segment 
} from "semantic-ui-react";
import { request, setAuthToken } from "services/axios_interceptor";
import styled from "styled-components";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'


function SignupModal({
  showSignup,
  closeModal,
  showIndividualModal,
  showCompanyModal
}: {
  showSignup: boolean;
  closeModal: () => void;
  showIndividualModal: () => void;
  showCompanyModal: () => void;
}) {
  
  const { t } = useTranslation();
  return (
    <Modal onClose={() => closeModal()} open={showSignup} size="tiny">
      <Modal.Header>{t("SignUpModalHeader")}</Modal.Header>
      <Modal.Content>
      <Grid columns='equal'>
    <GridColumn style={{ cursor:'pointer' }} onClick={() => showIndividualModal()}>
      <Segment>{t("IndividualUser")}</Segment>
    </GridColumn>
    <GridColumn style={{ cursor:'pointer' }} onClick={() => showCompanyModal()}>
      <Segment>{t("InstitutionalUser")}</Segment>
    </GridColumn>
  </Grid>
      </Modal.Content>
    </Modal>
  );
}

const S = {
  StyledColumn: styled(Grid.Column)`
    max-width: 450px;
  `,
};

export default SignupModal;
