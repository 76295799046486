import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
    Grid,
    Header,
    ModalActions,
    Modal,
    Button,
    ListItem,
    List,
    ListHeader,
    Icon
} from "semantic-ui-react";
import styled from "styled-components";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import { UserContext } from "App";
import { useContext } from "react";

function JoinUsEnterprise({opened,
    openModal,
    closeModal}:
    {
        opened: any,
        openModal: () => void,
        closeModal: () => void
    }) {
    
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const { t } = useTranslation();

    return (

        <Modal size="tiny"
            centered={false}
            open={opened}
            onClose={() => closeModal()}
            onOpen={() => openModal()}
            >
            <Modal.Header as="h2" color="black" textAlign="center">{t("EnterpriceModalHeader")}</Modal.Header>
            <Modal.Content>
                <Grid textAlign="center" verticalAlign="middle">
                    <S.StyledColumn>
                        <List >
                      <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="phone" />{t("DetailsModalEnterprise1")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="mail" />{t("DetailsModalEnterprise2")}</ListHeader></ListItem>
                      <ListItem style={{ lineHeight: '2em' }} ><ListHeader><Icon className="location arrow" />{t("DetailsModalEnterprise3")}</ListHeader></ListItem>
                      
                    </List>
                    </S.StyledColumn>
                </Grid>
            </Modal.Content>
            <ModalActions>
                <Button onClick={() => closeModal()}>OK</Button>
            </ModalActions>
        </Modal>

    );
}

const S = {
    Link: styled.span`
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  `,
    StyledColumn: styled(Grid.Column)`
    max-width: 450px;
  `,
};

export default JoinUsEnterprise;
