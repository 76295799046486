import React, { useEffect } from "react";
import { UserContext } from "App";
import { useContext } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
  Card,
  MenuItem
} from "semantic-ui-react";
import { request, setAuthToken } from "services/axios_interceptor";
import toast from "react-hot-toast";

import { MarketOverview, Timeline } from "react-ts-tradingview-widgets";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

export const HomepageHeading = function() {
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
      <Container text >
          <Header
              as="h1"
              content={t("StockBrief")}
              inverted
              style={{
                  fontSize: "3em",
                  fontWeight: "normal",
                  marginBottom: 0,
                  marginTop: "1em",
              }}
    />
    <Header
      as="h2"
      content={t("HomeHeadercontent2")}
      inverted
      style={{
        fontSize: "1.7em",
        fontWeight: "normal",
        marginTop: "1.5em",
      }}
    />
    <Button primary size="huge" onClick={() => {navigate("/education/AI")}}>
      {t("LearnMoreButton")}
      <Icon name="arrow right" />
    </Button>
  </Container>
)};

export const HomePage = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  const navigateToPage = (page: string) => {
    navigate(page);
  };
  return (
    <>
      <Segment
        inverted
        textAlign="center"
        style={{ minHeight: 350, padding: "1em 0em" }}
        vertical
      >
        <HomepageHeading />
      </Segment>
       
      {/* {user.loggedIn && (
        <Segment style={{ padding: "8em 0em" }} vertical>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" style={{ fontSize: "2em", marginLeft: "25%" }}>
                  StockBrief investment recommendations
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
               <SharesAndIndexes /> 
            </Grid.Row>
          </Grid>
        </Segment>
      )}  */}
      
      <Segment style={{ padding: "0em", }} vertical>
        <Grid celled="internally" columns="equal" stackable>
          <Grid.Row textAlign="center">
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em", cursor:'pointer' }} onClick={() => {navigateToPage("stocks")}}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                {t("HomeStocksButton")}
              </Header>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em", cursor:'pointer' }} onClick={() => {navigateToPage("indexes")}}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                {t("HomeIndexesButton")}
              </Header>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em", cursor:'pointer' }} onClick={() => {navigateToPage("sentyment")}}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                {t("HomeSentimentButton")}
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {/* {user.loggedIn && (
        <Segment style={{ padding: "8em 0em" }} vertical>
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column>
                <Header as="h2" style={{ fontSize: "2em", marginLeft: "25%" }}>
                  SENTYMENT
                </Header>
                <Header as="h3" style={{ fontSize: "2em", marginLeft: "25%" }}>
                  Analizuj nastroje na rynku przy pomocy ai
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <TilesComponent />
            </Grid.Row>
          </Grid>
        </Segment>
      )} */}

      <Segment style={{ padding: "8em 0em" }} vertical content='Stackable Grid'  >
        <Grid container stackable verticalAlign="middle">
          <Grid.Row >
            <Grid.Column Stackable width={16}>
              <Header as="h3" style={{ fontSize: "2em" }} >
                {t("HomeSegmentText")}
              </Header >
              <Header textAlign="justified">
              <p style={{ fontSize: "1.33em" }}> {t("HomeSegmentText2")}</p>
              
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: "0em" }} vertical content='Responsive Grid with Variations'>
        <Grid celled="internally" columns="equal" container divided stackable>
          <Grid.Row textAlign="center" >
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3" style={{ fontSize: "2em" }} content='Text Container'>
                {t("HomeSegmentTextPrognoses")}
              </Header>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3" content='Text Container' style={{ fontSize: "2em" }} >
               {t("HomeSegmentTextInstruments")}
              </Header>
            </Grid.Column>
            <Grid.Column  style={{ paddingBottom: "5em", paddingTop: "5em" }}>
              <Header as="h3" style={{ fontSize: "2em" }} Stackable content='Text Container'>
                {t("HomeSegmentTextMarketAnalysis")}
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: "8em 0em" }} vertical content='Nested Stackable Grid'>
        <Grid container Stackable verticalAlign="middle">
          <Grid.Row Stackable>
            <Grid.Column width={8}>
              <MarketOverview
                colorTheme="dark"
                height={400}
                width="100%"
                showFloatingTooltip
              ></MarketOverview>
            </Grid.Column>
            <Grid.Column Stackable width={8}>
              <Timeline
                colorTheme="dark"
                feedMode="market"
                market="crypto"
                height={400}
                width="100%"
              ></Timeline>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

export const Footer = () => {
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  return (
  <>
  <Segment Stackable style={{ padding: "2em 0em" }} vertical content='Nested Stackable Grid'>
    <Grid Stackable verticalAlign="middle">
      <Grid.Row Stackable textAlign="center">
        <Grid.Column Stackable justified>
          <b>Projekt dofinansowany z Funduszy Europejskich - StockBrief - system algorytmów big data 
             analizujących dane finansowe oraz zmiany sentymentu opinii inwestorskich i generujący 
             prognozy giełdowe bazując na strukturach głębokich sieci neuronowych
          </b>
        </Grid.Column> 
      </Grid.Row>
    </Grid>
    <Grid style={{ padding: "0em" }}>
      <Grid.Column Stackable>
        <Grid Stackable verticalAlign="middle">
          <Grid.Row centered style={{padding:'0em'}}>
            <Grid.Column Stackable width={4}>
              <Image
                  rounded
                  size="medium"
                  src={require("../Home/images/logo_FE_1.jpg")}
              />
            </Grid.Column>
            <Grid.Column Stackable width={4}>
              <Image
                  rounded
                  size="medium"
                  src={require("../Home/images/znak_barw_rp_poziom_bez_ramki_rgb.png")}
              />
            </Grid.Column>
            <Grid.Column Stackable width={4}>
              <Image
                  rounded
                  size="medium"
                  src={require("../Home/images/NCBR_logo_pl_crop.png")}
              />
            </Grid.Column>
            <Grid.Column Stackable width={4}>
              <Image
                  rounded
                  size="medium"
                  src={require("../Home/images/logo_UE_rgb-1.jpg")}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>    
  </Segment>      

    <Segment Stackable inverted vertical style={{ padding: "5em 0em" }} fixed={"bottom"}>
      <Container>
        <Grid divided inverted Stackable>
          <Grid.Row>
            <Grid.Column Stackable width={4}>
              <Header inverted as="h4" content={t("FooterPrognosis")}  />
              <List Stackable link inverted content='Responsive Item'>
                <List.Item as="a" onClick={() => {navigate("/stocks")}}>{t("Stocks")}</List.Item>
                <List.Item as="a" onClick={() => {navigate("/indexes")}}>{t("Indexes")}</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column Stackable width={4}>
              <Header Stackable inverted as="h4" content={t("AboutUs")} />
              <List fixed Stackable link inverted content='Responsive Item'>
                <List.Item as="a" onClick={() => {navigate("/about-us")}}>AI Forest sp. z o.o.</List.Item>
                <List.Item as="a" onClick={() => {navigate("/offer")}}>{t("FooterServices")}</List.Item>
                <List.Item as="a">{t("FooterContactUs")}</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column Stackable width={4}>
              <Header inverted as="h4" content={t("FooterEducation")} />
              <List Stackable link inverted content='Responsive Item'>
                <List.Item as="a" onClick={() => {navigate("/education/AIEducation")}}>AI</List.Item>
                <List.Item as="a"onClick={() => {navigate("/education/SentimentAnalysis")}}>{t("SentimentAnalysis")}</List.Item>
                <List.Item as="a" onClick={() => {navigate("/education/MarketSentiments")}}>{t("MarketSentiments")}</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column Stackable width={4}>
              <Header inverted as="h4" content= {t("FooterDocumentation")}/>
              <List Stackable link inverted content='Responsive Item'>
                <List.Item as="a">{t("FooterLegalInformation")}</List.Item>
                <List.Item as="a" onClick={() => {navigate("documentation/Terms&Conditions")}}>{t("FooterTermsConditions")}</List.Item>
                <List.Item as="a" onClick={() => {navigate("documentation/PrivacyPolicy")}}>{t("FooterPolicy")}</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
</>
  );
};
