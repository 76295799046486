import React from "react";
import { Container, Segment } from "semantic-ui-react";

export function HistoricalPage() {
  return (
    <Container>
      <Segment>Historical page</Segment>
    </Container>
  );
}
