import React from "react";
import { useState } from "react";
import {
    Container,
    Header,
    Segment,
    List,
    ListItem,
    ListHeader,
    Card,
    HeaderContent,

} from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";
import { useEffect } from "react";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },

}

export const MarketSentimentsHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("MarketSentimentsHeader")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};

export function MarketSentiments() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 200 }}
                vertical>
                <MarketSentimentsHeading /> 
            </Segment>

            <Segment vertical >
                {/* <Header
                    as='h2'
                    content={t("")}
                    style={style.h3}
                    textAlign='center'
                />  */}
                <Container style={{ minHeight: "10rem" }} textAlign="justified">
                    <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>
                        <Header as='h2' textAlign='center' style={{ marginBottom: "2em" }}>
                            {t("MarketSentimentsTitle")}
                        </Header>
                        <HeaderContent textAlign="justified">
                            {t("MarketSentimentsContent")}
                        </HeaderContent>
                        <Container textAlign="justified">
                            <Header style={{ marginTop: "2em" }}>
                                {t("MarketSentimentsubtitle")}
                            </Header>
                            <HeaderContent>
                                {t("MarketSentimentContent1")}
                            </HeaderContent>
                            <HeaderContent style={{ marginTop: "1em" }}>
                                {t("MarketSentimentContent2")}
                            </HeaderContent>

                            <ListHeader style={{ marginTop: "1em" }} as="h3">
                                {t("MarketSentimentSubTitle2")}
                            </ListHeader>
                            <HeaderContent>
                                {t("MarketSentimentContentSbTitle2")}
                            </HeaderContent>
                            <List ordered>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader >{t("EconomicIndicatorsBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("MarketSentimentContentSbTitle2")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("EconomicIndicatorsBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("EconomicIndicatorsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("CorporateEarningsNewsBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("CorporateEarningsNewsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("MarketRumorsSpeculationBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("MarketRumorsSpeculationItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("BehavioralFinanceBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("BehavioralFinanceItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h3">
                                {t("MarketSentimentsOnFinancialMarketsTitle")}
                            </ListHeader>
                            <Container textAlign="justified">
                                <p>{t("MarketSentimentsOnFinancialMarketsContent")}</p>
                            </Container>

                            <List bulleted>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("PriceVolatilityBold")}</b>
                                        {t("PriceVolatilityItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("MarketTrendsBold")}</b>
                                        {t("MarketTrendsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("InvestmentStrategiesBold")}</b>
                                        {t("InvestmentStrategiesItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h3">
                                {t("MeasuringMarketSentimentsTitle")}
                            </ListHeader>
                            <Container textAlign="justified">
                                <p>{t("MeasuringMarketSentimentsContent")}</p>
                            </Container>

                            <List ordered>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("SentimentIndicatorsBold")}</b>
                                        {t("SentimentIndicatorsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("SocialMediaNewsAnalysisBold")}</b>
                                        {t("SocialMediaNewsAnalysisItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("SurveysPollsBold")}</b>
                                        {t("SurveysPollsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("TechnicalAnalysisBold")}</b>
                                        {t("TechnicalAnalysisItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h3">
                                {t("ChallengesAndFutureOfMarketSentimentsTitle")}
                            </ListHeader>
                            <p>{t("ChallengesAndFutureOfMarketSentimentsContent")}</p>
                            <List bulleted>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("SubjectivityBold")}</b>
                                        {t("SubjectivityItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("OverreactionBold")}</b>
                                        {t("OverreactionItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("ComplexityOfGlobalMarketsBold")}</b>
                                        {t("ComplexityOfGlobalMarketsItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <HeaderContent>
                                {t("FutureMarketSentimentsFinalText")}
                            </HeaderContent>
                            <Container textAlign="justified">
                                <Header style={{ marginTop: "1em" }}>{t("MarketSentimentConclusion")}</Header>
                                <HeaderContent>{t("MarketSentimentConclusionContent")}</HeaderContent>
                            </Container>
                        </Container>

                    </Segment>

                </Container>
            </Segment>
        </>
    );
}