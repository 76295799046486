import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { isValidEmail, request, setAuthToken } from "services/axios_interceptor";
import styled from "styled-components";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import { UserContext } from "App";
import { useContext } from "react";


type LoginForm = {
  login: string;
  password: string;

};

function LoginModal({
  showLogin,
  closeModal,
  openSignup,
  openResetForm,
  setLoggedIn
}: {
  showLogin: boolean;
  closeModal: () => void;
  openSignup: () => void;
  openResetForm: () => void;
  setLoggedIn: (logged: any) => void;
}) {
  const [loginForm, setLoginForm] = useState<LoginForm>({
    login: "",
    password: "",
  });
  const navigate = useNavigate();
  const user = useContext(UserContext);
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        submit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [loginForm]);

  const submit = () => {
    if(isValidEmail(loginForm.login) === false){
      toast.error(
        t("CreateAccountIndivEmailIsValid")
      );
      return;
    }
    request("POST", "/login", {
      username: loginForm.login,
      password: loginForm.password,
    })
      .then((response) => {
        //TODO: adjust structure of response when backend part implemented
        setAuthToken(response.data.jwt);
        if(response.data.jwt){
          user.updateLogged(true);
          setLoggedIn(true);
          user.loggedIn = true;
        }
        closeModal();
        navigate("/")
      })
      .catch((e) =>
        toast.error(
          (t("serverErrorMessage")),
        )
      );
  };
  
  const { t } = useTranslation();
  return (
    
    <Modal onClose={() => closeModal()} open={showLogin} size="tiny">
      <Modal.Header>{t("LoginModalHeader")}</Modal.Header>
      <Modal.Content>
        <Grid textAlign="center" verticalAlign="middle">
          <S.StyledColumn>
            <Header as="h2" color="black" textAlign="center">
               {t("LoginHeader")}
            </Header>
            <Form size="large" onSubmit={() => submit()}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address*"
                  onChange={(e) =>
                    setLoginForm((prevState) => ({
                      ...prevState,
                      login: e.target.value,
                    }))
                  }
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password*"
                  type="password"
                  onChange={(e) =>
                    setLoginForm((prevState) => ({
                      ...prevState,
                      password: e.target.value,
                    }))
                  }
                />
              </Segment>
            </Form>
            <Message>
              <span>{t("FormMessage")} </span>
              <S.Link onClick={() => openSignup()}>{t("SignUpForm")}</S.Link>
            </Message>
            <Message>
              <S.Link onClick={() => openResetForm()}>{t("ForgotPasswordLink")}</S.Link>
            </Message>
          </S.StyledColumn>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => closeModal()}>
          {t("CancelModalButton")}
        </Button>
        <Button
          color="green"
          onClick={() => submit()}
          disabled={
            loginForm.login.length === 0 || loginForm.password.length === 0
          }
          type="submit"
        >
          {t("SubmitModalButton")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

const S = {
  Link: styled.span`
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  `,
  StyledColumn: styled(Grid.Column)`
    max-width: 450px;
  `,
};

export default LoginModal;
