import axios from "axios";
import { jwtDecode } from "jwt-decode";

//TODO: replace with env variable
axios.defaults.baseURL = "https://stockbrief.pl/backend/api";
axios.defaults.headers.post["Content-Type"] = "application/json";

export const getAuthToken = () => {
  const token = window.localStorage.getItem("auth_token");
  if (isAuthTokenValid(token)) {
    return token;
  } else {
    return null;
  }
};

export const isAuthTokenValid = (token: string | null): boolean => {
  if (token && token.length > 0) {
    const decodedToken = jwtDecode(token);
    const currentDate = new Date();
    if (decodedToken.exp && decodedToken.exp * 1000 < currentDate.getTime()) {
      return false;
    } else {
      return true;
    }
  } else {
    return false
  }
}

export const setAuthToken = (token: string) => {
  if (isAuthTokenValid(token)) {
    window.localStorage.setItem("auth_token", token)
  } else {
    window.localStorage.removeItem("auth_token")
  }
};

export const request = async (
  method: string | undefined,
  url: string | undefined,
  data: unknown
) => {
  let headers = {};
  const authToken = getAuthToken()
  if (authToken !== null && authToken !== "null") {
    headers = { Authorization: `Bearer ${authToken}` };
  }
  const request = axios({ method: method, headers: headers, url: url, data: data })
  const requestData = await request;
  if (requestData.data.jwt) {
    setAuthToken(requestData.data.jwt)
  }
  return request;

};

export const isValidEmail = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (emailPattern.test(email)) {
    return true;
  } else {
    return false;
  }
};