import React from "react";
import { useEffect } from "react";
import {
    Container,
    Header,
    Segment,
    List,
    ListItem,
    HeaderContent,
    ListHeader,
    ListList,
    ListContent
} from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import i18n from "i18n";
import { LinkText } from "components/LinkText";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },

}

export const TermsConditionsHeading = function () {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Container text >
            <Header
                as="h1"
                content={t("Terms&ConditionsContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};

export function TermsConditions({ link1, link2 }: { link1: any, link2: string }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 220 }}
                vertical>
                <TermsConditionsHeading />
            </Segment>

            <Segment vertical >
                <Container style={{ minHeight: "10rem" }} textAlign="justified">
                    <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>
                        <Header as='h2' textAlign='center' style={{ marginBottom: "2em" }}>
                            {t("Term&ConditionsTitle")}
                        </Header>
                        <HeaderContent textAlign="justified">
                            {t("Term&ConditionsText")}
                        </HeaderContent>

                        <Container textAlign="justified">
                            <List ordered>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("DefinitionsBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("DefinitionText")}
                                    </ListItem>
                                    <ListList as='ul'>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            <ListItem >
                                                <b>{t("ServiceBold")}</b>
                                                <Trans
                                                    i18nKey="ServiceItem"
                                                    components={{
                                                        link1: <LinkText to={link1} title="link1" />
                                                    }} />

                                            </ListItem>
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            <ListItem >
                                                <b>{t("UserBold")}</b>
                                                {t("UserItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            <ListItem >
                                                <b>{t("AccountBold")}</b>
                                                {t("AccountItem")}
                                            </ListItem>
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            <ListItem >
                                                <b>{t("ContentBold")}</b>
                                                {t("ContentItem")}
                                            </ListItem>
                                        </ListItem>
                                    </ListList>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("ServiceOfferingsBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("ServiceOfferingsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("UseOfTheServiceBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("UseOfTheServiceItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("PaymentsAndSubscriptionBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("PaymentsAndSubscriptionItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("PaymentOperatorBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>

                                        <Trans
                                            i18nKey="PaymentOperatorItem"
                                            components={{
                                                link2: <LinkText to={link2} title="link2" />
                                            }} />
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("PersonalDataBold")}</ListHeader>
                                    <ListItem >
                                        {t("PersonalDataItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("SecurityBold")}</ListHeader>
                                    <ListItem >
                                        {t("SecurityItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("LiabilityAndRulesOfUseBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("LiabilityAndRulesOfUseItem")}
                                    </ListItem>
                                    <ListList as='ul'>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("LiabilityAndRulesOfUse1")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("LiabilityAndRulesOfUse2")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("LiabilityAndRulesOfUse3")}
                                        </ListItem>
                                        <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                            {t("LiabilityAndRulesOfUse4")}
                                        </ListItem>
                                    </ListList>
                                    <ListContent style={{ marginLeft: "0.5em" }}>
                                        {t("LiabilityAndRulesOfUse1Content")}
                                    </ListContent>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("AdvertisementsExternalContentBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("AdvertisementsExternalContentItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("ChangesToTheTermsOfUse")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("ChangesToTheTermsOfUseItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("AcceptanceOfTheTermsBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("AcceptanceOfTheTermsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("ContactBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("ContactItem")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <ListHeader as="h4">{t("DateOfTheTerms")}</ListHeader>
                        </Container>
                    </Segment >
                </Container >

            </Segment>
        </>
    )
}