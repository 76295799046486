import React from "react";
import { createContext, useEffect, useState } from "react";
import "./App.css";
import toast, { Toaster } from "react-hot-toast";
import { Routing } from "routing";
import { getAuthToken, request } from "services/axios_interceptor";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";
import { ScrollToTop } from "components/ScrollToTop/ScrollToTop";

export default function App() {
  const { t } = useTranslation();
  const [loggedIn, setLoggedIn] = useState(false);
  const [indexNames, setIndexNames] = useState<
    Array<{ code: string; name: string }>
  >([]);
  const [shareNames, setShareNames] = useState<
    Array<{ code: string; name: string }>
  >([]);
  const token = getAuthToken();
  const updateLogged = (value: boolean) => {
    setLoggedIn(value);
  }

  useEffect(() => {
    if (token && token.length > 0) {
      setLoggedIn(true);
    }

    request("GET", `/instruments/indexes-for-recommendations`, {})
      .then((response) => {
        setIndexNames(response.data.data);
      })
      .catch((e) =>
        console.error(
          t("errorMessage")
        )
      );

    request("GET", `/instruments/shares-for-recommendations`, {})
      .then((response) => {
        setShareNames(response.data.data);
      })
      .catch((e) =>
        console.error(
          t("errorMessage")
        )
      );
  }, [token]);

  return (
    <>
      <UserContext.Provider
        value={{
          loggedIn: loggedIn,
          indexNames: indexNames,
          shareNames: shareNames,
          updateLogged: updateLogged,
        }}
      > 
      </UserContext.Provider>
      <Toaster />
      <Routing />
      {/* <ScrollToTop />   */}
    </>
  );
}

export const UserContext = createContext({
  loggedIn: false,
  indexNames: [{}],
  shareNames: [{}],
  updateLogged: (value: boolean) => console.log(value),
});
