import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
    Button,
    Form,
    Grid,
    Header,
    Modal,
    Segment,
} from "semantic-ui-react";
import { request, setAuthToken } from "services/axios_interceptor";
import styled from "styled-components";
import i18n from "i18n";
import { useTranslation } from 'react-i18next'

type resetForm = {
    username: string;

};

function ResetPassordModal({
    showResetForm,
    closeModal,

}: {
    showResetForm: boolean;
    closeModal: () => void;

}) {
    const [resetForm, setResetForm] = useState<resetForm>({
        username: "",

    });

    // useEffect(() => {
    //     const listener = (event: any) => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             event.preventDefault();
    //             reset();
    //         }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }, [resetForm]);

    const reset = () => {

        request("POST", "/start-reset-password", {
            username: resetForm.username,
        })
            .then((response) => {
                setAuthToken(response.data.jwt);
                toast.success(t("resetPassEmailMessage"));
                closeModal();

            })
            .catch((e) =>
                toast.error(
                    (t("serverErrorMessage")),
                )
            );
    };

    const { t } = useTranslation();
    return ( 

        <Modal onClose={() => closeModal()} open={showResetForm} size="tiny">
            <Modal.Content>
                <Grid textAlign="center" verticalAlign="middle">
                    <S.StyledColumn>
                        <Header as="h2" color="black" textAlign="center">
                            {t("ResetPasswordModalHeader")}
                        </Header>
                        <p>{t("ResetPasswordModalMessage")}</p>
                        <Form size="large" onSubmit={() => reset()}>
                            <Segment stacked>
                                <Form.Input
                                    fluid
                                    icon="user"
                                    iconPosition="left"
                                    placeholder={t("resestPasswordPlaceholder")}
                                    onChange={(e) =>
                                        setResetForm((prevState) => ({
                                            ...prevState,
                                            username: e.target.value,
                                        }))
                                    }
                                />

                            </Segment>
                        </Form>

                    </S.StyledColumn>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" onClick={() => closeModal()}>
                    {t("CancelModalButton")}
                </Button>
                <Button
                    color="green"
                    onClick={() => reset()}
                    type="reset"
                >
                    {t("ResetModalButton")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

const S = {
    Link: styled.span`
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  `,
    StyledColumn: styled(Grid.Column)`
    max-width: 450px;
  `,
};

export default ResetPassordModal;
