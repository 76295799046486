import React from "react";
import { Container, Segment } from "semantic-ui-react";

export default function PrognosisPage() {
  return (
    <Container>
      <Segment>Prognosis page</Segment>
    </Container>
  );
}
