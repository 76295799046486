import React from "react";
import { useEffect } from "react";
import {
    Container,
    Grid,
    Header,
    Segment,
    List,
    ListItem,
    ListHeader,
    Image,
    ListDescription,
    
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },
}

export const StockMarketGuideArticleHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("AIHeaderContent")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};
export function StockMarketGuideArticle() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <>
            <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 250, padding: "1em 0em" }}
                vertical>
                <StockMarketGuideArticleHeading />
            </Segment>

            <Container style={{ minHeight: "10rem" }}>
                <Segment style={{ padding: "3em" }} vertical content='Responsive Grid with Variations'>
                    <Image rounded src='/images/StockInvestment.jpg' />
                    <a target="_blank" href="https://www.vecteezy.com/free-photos/growth">Source: Growth Stock photos by Vecteezy</a>
                    <Grid.Column style={{ marginTop: "2em" }}>
                        <Segment>
                            <ListHeader as="h3">
                                {t("Article4Title")}
                            </ListHeader>
                            <p>{t("ShortDescriptionArticle4")}</p>

                            <ListHeader as="h4">
                                {t("Article4Subtitle")}
                            </ListHeader>
                            <p>{t("SubtitleShortDescriptionArt4")}</p>
                            <List ordered>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("Art4SubTitle")} </ListHeader>
                                    <p>{t("Art4ShortSubtitleText")}</p>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("HighReturnsBold")} </ListHeader>
                                    <p>{t("HighReturnsItem")}</p>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("DividendIncomeBold")}</ListHeader>
                                    <p>{t("DividendIncomeItem")}</p>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("RiskTreatmentBold")} </ListHeader>
                                    <p>{t("RiskTreatmentItem")}</p>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("DiversificationBold")} </ListHeader>
                                    <p>{t("DiversificationItem")}</p>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("OwnershipInfluenceBold")} </ListHeader>
                                    <p>{t("OwnershipInfluenceItem")}</p>
                                </ListItem>
                            </List>
                            <ListHeader as="h4">
                                {t("StartInvestingTitle")}
                            </ListHeader>
                            <p>{t("ShortDescriptionStartInvesting")}</p>
                            <List ordered>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("FinancialGoalsBold")}</ListHeader>
                                    <ListItem >
                                        {t("FinancialGoalItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("EstablishBudgetBold")}</ListHeader>
                                    <ListItem>
                                        {t("EstablishBudgetItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("BrokerageAccountBold")}</ListHeader>
                                    <ListItem>
                                        {t("BrokerageAccountItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("ResearchSelectStocksBold")}</ListHeader>
                                    <ListItem>
                                        {t("ResearchSelectStocksItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("DiversifyPortfolioBold")}</ListHeader>
                                    <ListItem>
                                        {t("DiversifyPortfolioItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("InvestRegularlyBold")}</ListHeader>
                                    <ListItem>
                                        {t("InvestRegularlyItem")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <ListHeader as="h4">
                                {t("InvestmentStrategiesTitle")}
                            </ListHeader>
                            <p>{t("InvestmentStrategiesShortDescription")}</p>
                            <List ordered>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("ValueInvestingBold")}</ListHeader>
                                    <ListItem >
                                        {t("ValueInvestingItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("GrowthInvestingBold")}</ListHeader>
                                    <ListItem>
                                        {t("GrowthInvestingItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("IncomeInvestingBold")}</ListHeader>
                                    <ListItem>
                                        {t("IncomeInvestingItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }} >
                                    <ListHeader>{t("IndexInvestingBold")}</ListHeader>
                                    <ListItem>
                                        {t("IndexInvestingItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("ActiveTradingBold")}</ListHeader>
                                    <ListItem>
                                        {t("ActiveTradingItem")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <ListHeader as="h4">
                                {t("ManagingRisksTitle")}
                            </ListHeader>
                            <p>{t("ManagingRisksShortDescription")}</p>
                            <List ordered>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("DiversificationListBold")}</ListHeader>
                                    <ListItem >
                                        {t("DiversificationListItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }} >
                                    <ListHeader>{t("RiskToleranceBold")}</ListHeader>
                                    <ListItem>
                                        {t("RiskToleranceItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("RegularMonitoringBold")}</ListHeader>
                                    <ListItem>
                                        {t("RegularMonitoringBold")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }} >
                                    <ListHeader>{t("AvoidEmotionalDecisionBold")}</ListHeader>
                                    <ListItem>
                                        {t("AvoidEmotionalDecisionItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }} >
                                    <ListHeader>{t("EducateYourselfBold")}</ListHeader>
                                    <ListItem>
                                        {t("EducateYourselfItem")}
                                    </ListItem>
                                </ListItem>
                                <ListHeader as="h4">
                                    {t("MistakesAvoidTitle")}
                                </ListHeader>
                            </List>
                            <p>{t("ShortDescriptionMistakesAvoid")}</p>
                            <List ordered>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("LackResearchBold")}</ListHeader>
                                    <ListItem >
                                        {t("LackResearchItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("OvertradingBold")}</ListHeader>
                                    <ListItem>
                                        {t("OvertradingItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("IgnoringDiversBold")}</ListHeader>
                                    <ListItem>
                                        {t("IgnoringDiversItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("MarketTimingBold")}</ListHeader>
                                    <ListItem>
                                        {t("MarketTimingItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginLeft: "1em" }}>
                                    <ListHeader>{t("NeglectingFeesBold")}</ListHeader>
                                    <ListItem>
                                        {t("NeglectingFeesItem")}
                                    </ListItem>
                                </ListItem>
                            </List>
                            <ListHeader style={{ marginTop: "0em", marginBottom: "0.5em" }}><b>{t("ConclusionTitleArt4")}</b></ListHeader>
                            <ListDescription>{t("ConclusionDescriptionArt4")}</ListDescription>
                        </Segment>
                    </Grid.Column>
                </Segment >
            </Container >
        </>
    )
}
