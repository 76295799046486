import React, { SyntheticEvent } from "react";
import { UserContext } from "App";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  Button,
  Grid,
  Header,
  Icon,
  Pagination,
  Segment,
  Tab,
  Table,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { request } from "services/axios_interceptor";
import i18n from "i18n";
import { useTranslation } from 'react-i18next';

type Recommendation = {
  forecastLength: number;
  id: number;
  ppv: number;
  prediction: number;
  priceChange: number;
  recommendation: boolean;
  recommendationDate: Array<number>;
  threshold: number;
};

const nameMapping = [
  {
    code: "wig20",
    name: "WIG 20",
  },
  {
    code: "wig80",
    name: "WIG 80",
  },
  {
    code: "pkn-close",
    name: "PKN Orlen",
  },
  {
    code: "kgh-close",
    name: "KGHM",
  },
];

const chunk = (arr: [], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
 
  
export function InstrumentHistory2() {
  const { instrument } = useParams();
  const { t } = useTranslation();
  const [recommendations, setRecommendations] = useState<
    Array<{
      instrumentName: string;
      recommendationDate: Array<number>;
      recommendations: Array<Recommendation>;
    }>
  >([]);
 
  useEffect(() => {
    // request("GET", `/recommendations/xai/limr-forests/33589`, {})
    //   .then((response) => {
    //     // setRecommendations(response.data.data);
    //     console.log(response.data)
    //   })
    //   .catch((e) =>
    //     toast.error(
    //       "It looks like we encountered a problem while trying to reach the server.",
    //       { position: "bottom-right" }
    //     )
    //   );

    request("GET", `/instruments/indexes-for-recommendations`, {})
      .then((response) => {
      })
      .catch((e) =>
        toast.error(
          t("errorMessage"),
          { position: "bottom-right" }
        )
      );

    request("GET", `/recommendations/${instrument}/all`, {})
      .then((response) => {
        setRecommendations(response.data.data);
      })
      .catch((e) =>
        toast.error(
          t("errorMessage"),
          { position: "bottom-right" }
        )
      );
  }, [instrument]);

  if (!instrument) {
    return <></>;
  }

  const panes = [
    {
      menuItem: t("IndexesTHeaderCellfirstPeriod"),
      render: () => (
        <HistoryTable
          range={30}
          instrument={instrument}
          recommendations={recommendations}
        />
      ),
    },
    {
      menuItem: t("ITcellSecondPeriod"),
      render: () => (
        <HistoryTable
          range={90}
          instrument={instrument}
          recommendations={recommendations}
        />
      ),
    },
    {
      menuItem: t("IndexesTHeaderCellthridPeriod"),
      render: () => (
        <HistoryTable
          range={180}
          instrument={instrument}
          recommendations={recommendations}
        />
      ),
    },
  ];
 
  return (
    <Segment style={{ padding: "8em 0em" }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={5}>
            <Header>
              {nameMapping.find((name) => name.code === instrument)?.name}
            </Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Tab panes={panes} renderActiveOnly={true} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}


const HistoryTable = ({
  range,
  instrument,
  recommendations,
}: {
  range: number;
  instrument: string;
  recommendations: Array<{
    instrumentName: string;
    recommendationDate: Array<number>;
    recommendations: Array<Recommendation>;
  }>;
}) => {
  console.log(range);
  const navigate = useNavigate();
  let recommendationIndex = 0;
  if (range === 30) {
    recommendationIndex = 2;
  } else if (range === 90) {
    recommendationIndex = 1;
  } else if (range === 180) {
    recommendationIndex = 0;
  }
  const [activePage, setActivePage] = useState(1);
  const paginatedRecommendations = chunk(recommendations as [], 20);
  const { t } = useTranslation();
  function selectPage(e: SyntheticEvent, data: any) {
    setActivePage(data.activePage);
  
  }
  if (paginatedRecommendations.length === 0) {
    return <></>;
  }
  console.log(paginatedRecommendations);
  return (
    
    <>
      <Segment style={{}} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row style={{ borderLeft: "1px solid rgba(34,36,38,.1)", borderRight: "1px solid rgba(34,36,38,.1)" }} >
            <Grid.Column width={4} >Threshold</Grid.Column>
            <Grid.Column width={4} >
              <b>{recommendations[0].recommendations[recommendationIndex].threshold}</b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ borderLeft: "1px solid rgba(34,36,38,.1)", borderRight: "1px solid rgba(34,36,38,.1)" }}>
            <Grid.Column width={4} >{t("PriceIncreaseRecommendationsTable")}</Grid.Column>
            <Grid.Column width={4}>
              <b>{recommendations[0].recommendations[recommendationIndex].priceChange}</b>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="1">{t("RecommendationDate")}</Table.HeaderCell>
            <Table.HeaderCell colSpan="1">{t("RecommendationsTitle")}</Table.HeaderCell>
            <Table.HeaderCell colSpan="1">{t("XAILimeTableTitle")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {paginatedRecommendations[activePage-1].map((recommendation: any) => {
            // const fittingForecastRecs = recommendation.recommendations.find((rec: any) => rec.forecastLength === 30)
            // console.log(fittingForecastRecs)

            return (
              
              <Table.Row>
                <Table.Cell>
                  {recommendation.recommendationDate
                    ? `${recommendation.recommendationDate[0]}-${recommendation.recommendationDate[1]}-${recommendation.recommendationDate[2]}`
                    : "-"}
                </Table.Cell>
                <Table.Cell>
                  {recommendation.recommendations[recommendationIndex] ? (
                    recommendation.recommendations[recommendationIndex].recommendation ? (
                      <>
                        {t("YesWord")} <Icon name="arrow up" color="green" />
                      </>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </Table.Cell>
                <Table.Cell><Button primary onClick={() => navigate(`/recommendation/history/${recommendation.recommendations[recommendationIndex].id}/xai-lime`)}>LIME</Button></Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Cell>
              <Pagination
                style={{ position: "relative", left: "24vh" }}
                activePage={activePage}
                totalPages={paginatedRecommendations.length}
                onPageChange={selectPage}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};
