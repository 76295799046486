import React from "react";
import { useState } from "react";
import {
    Container,
    Header,
    Segment,
    List,
    ListItem,
    ListHeader,
    Card,
    HeaderContent,
    ListList,

} from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";
import { useEffect } from "react";

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginBottom: '300px',
    },

}

export const SentimentAnalysisHeading = function () {
    const { t } = useTranslation();
    return (
        <Container text >
            <Header
                as="h1"
                content={t("SentimentAnalysisHeading")}
                inverted
                style={{
                    fontSize: "3em",
                    fontWeight: "normal",
                    marginBottom: 0,
                    marginTop: "1em",
                }}
            />
        </Container>
    )
};

export function SentimentAnalysis() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
             <Segment
                inverted
                textAlign="center"
                style={{ minHeight: 200, padding: "1em 0em" }}
                vertical>
                <SentimentAnalysisHeading />
            </Segment>  

            <Segment vertical >
                <Container stackable verticalAlign="middle">
                    <Segment vertical content='Responsive Grid with Variations'>
                        <Header as='h2' textAlign="center" style={{ marginTop: "2em" }}>
                            {t("SentimentAnalysisTitle")}
                        </Header>

                        <Container>
                            <Header style={{ marginTop: "2em" }}>
                                {t("SentimentAnalysisSubTitle")}
                            </Header>
                            <HeaderContent>
                                {t("SentimentAnalysisSubTitleContent")}
                            </HeaderContent>

                            <ListHeader style={{ marginTop: "1em" }} as="h3">
                                {t("HowSentimentAnalysisWorks")}
                            </ListHeader>
                            <HeaderContent>
                                {t("HowSentimentAnalysisWorksText")}
                            </HeaderContent>
                            <List ordered>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader >{t("TextPreprocessingBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("TextPreprocessingItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("FeatureExtractionBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("FeatureExtractionItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "1em" }}>
                                    <ListHeader>{t("SentimentClassificationBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("SentimentClassificationItem")}
                                        <ListList as='ul'>
                                            <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                                <ListHeader>{t("RuleBasedApproachBold")}</ListHeader>
                                                <ListItem style={{ marginTop: "0.5em" }}>
                                                    {t("RuleBasedApproachItem")}
                                                </ListItem>
                                            </ListItem>
                                            <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                                <ListHeader>{t("MachineLearningBasedApproachBold")}</ListHeader>
                                                <ListItem style={{ marginTop: "0.5em" }}>
                                                    {t("MachineLearningBasedApproachItem")}
                                                </ListItem>
                                            </ListItem>
                                            <ListItem as='li' style={{ marginLeft: "0.5em" }}>
                                                <ListHeader>{t("HybridApproachBold")}</ListHeader>
                                                <ListItem style={{ marginTop: "0.5em" }}>
                                                    {t("HybridApproachItem")}
                                                </ListItem>
                                            </ListItem>
                                        </ListList>
                                    </ListItem>
                                </ListItem>
                                <ListItem style={{ marginTop: "0.5em" }}>
                                    <ListHeader>{t("PostProcessingAnalysisBold")}</ListHeader>
                                    <ListItem style={{ marginTop: "0.5em" }}>
                                        {t("PostProcessingAnalysisItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h3">
                                {t("ApplicationsOfSentimentAnalysisTitle")}
                            </ListHeader>
                            <Container textAlign="justified">
                                <p>{t("ApplicationsOfSentimentAnalysisContent")}</p>
                            </Container>

                            <List bulleted>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("CustomerFeedbackAnalysisBold")}</b>
                                        {t("CustomerFeedbackAnalysisItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("BrandMonitoringBold")}</b>
                                        {t("BrandMonitoringItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("MarketResearchBold")}</b>
                                        {t("MarketResearchItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("FinancialMarketPredictionsBold")}</b>
                                        {t("FinancialMarketPredictionsItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("PoliticalAnalysisBold")}</b>
                                        {t("PoliticalAnalysisItem")}
                                    </ListItem>
                                </ListItem>
                            </List>

                            <ListHeader as="h3">
                                {t("ChallengesAndFutureOfSentimentAnalysisTitle")}
                            </ListHeader>
                            <p>{t("ChallengesAndFutureOfSentimentAnalysisContent")}</p>
                            <List ordered>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("SarcasmAndIronyBold")}</b>
                                        {t("SarcasmAndIronyItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("ContextualUnderstandingBold")}</b>
                                        {t("ContextualUnderstandingItem")}
                                    </ListItem>
                                </ListItem>
                                <ListItem >
                                    <ListItem >
                                        <b>{t("MultilingualAnalysisBold")}</b>
                                        {t("MultilingualAnalysisItem")}
                                    </ListItem>   
                                </ListItem>
                            </List>
                            <HeaderContent textAlign="justified">
                                {t("SentimentAnalysisFinalText")}
                            </HeaderContent>
                            <Header>{t("Conclusion")}</Header>
                            <Container textAlign="justified">
                                <p>{t("ConclusionText")}</p>
                            </Container>
                        </Container>

                    </Segment>

                </Container>
            </Segment>
        </>
    )
}